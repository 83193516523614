import axios from "axios";
import { Services, Cryptography } from "mp-common-js";
import { ENV } from "../../env";

const API_URL = "/api/users/";

export const SECURTIY_API_URL = `https://www.${
  ENV !== "production" ? (ENV === "development" ? "dev" : ENV) + "-" : ""
}manage-mii.uk/api/security`;

export const decodeToken = (token) => {
  const parsedToken = JSON.parse(token);
  return Cryptography.decodeJwt(parsedToken.access_token);
};

// Register user
const register = async (userData) => {
  const response = await axios.post(API_URL, userData);

  if (response.data) {
    localStorage.setItem("user", JSON.stringify(response.data));
  }

  return response.data;
};

// Login user
const login = async ({ email, password }) => {
  // const response = await axios.post(API_URL + "login", userData);

  const response = await Services.SecurityService.signInCodeFlow(
    { username: email, password },
    "d57199b4-d51b-4a18-8cda-db33a3c5b582",
    null,
    null //params - user data
  );
  if (response.data) {
    // store token
    localStorage.setItem("token", JSON.stringify(response.data));

    localStorage.setItem(
      "user",
      Cryptography.decodeJwt(response.data.access_token).user_name
    );
  }
  // check if access_token
  // store access_token && refresh token - check if you need to store all response in localstorage
  return Cryptography.decodeJwt(response.data.access_token).user_name;
};

// Authenticate promo ready wearable
export const authenticatePromoReadyWearable = async (
  serialNumber,
  sessionId,
  stickyId
) => {
  const data = {
    sessionId: sessionId,
    serialNumber: serialNumber,
  };

  const headers = {
    awselb: stickyId,
    "Content-Type": "application/json",
  };

  const response = await axios.post(
    `${SECURTIY_API_URL}/cryptogram/promo-ready/token?client_id=8aae0abd-3997-4d92-8bc7-a49370792780`,
    data,
    { headers }
  );
  return response.data;
};

// Logout user
const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
};

const authService = {
  register,
  logout,
  login,
  authenticatePromoReadyWearable,
};

export default authService;
