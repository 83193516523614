import httpClient from "../../axios";
import { ENV } from "../../env";

export const API_URL = `https://www.${
  ENV !== "production" ? (ENV === "development" ? "dev" : ENV) + "-" : ""
}manage-mii.uk/api/das/info-collector`;

const getDevices = async (eventiD) => {
  const response = await httpClient.get(`${API_URL}/devices/?eventId=${eventiD}`);

  return response.data;
};

const importPassengers = async (passengers) => {
  const response = await httpClient.post(`${API_URL}/devices/import`, {
    devices: passengers,
  });
  return response.data;
};

const coachTrackerService = {
  getDevices,
  importPassengers
};

export default coachTrackerService;
