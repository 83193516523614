import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  getBalanceAndPin,
  savePersonalDetailsAction
} from "../../features/balance-and-pin/balanceAndPinSlice";
import usePrevious from "../../hooks/usePrevious";
import { getDeviceInfo } from "../../features/hugo-boss/hugoBossSlice";
import { formattedBalance } from "../../features/balance-and-pin/utils";
import LanguageSelect from "./LanguageSelect";
import Spinner from "../../components/Spinner";
import { AppStoreLinks } from "../pepcity/styles/Pepcity";

import packageJson from "../../../package.json";

import {
  Activation,
  Alert,
  BottomSection,
  Button,
  Explore,
  Footer,
  Hero,
  Item,
  ItemHeader,
  JoinBoBo,
  JourneyImageContainer,
  List,
  MainContainer,
  Navbar,
  Schedule,
  YourJourney
} from "./styles";

import heroImg from "./img/hero.png";
import appStoreLogo from "./img/app-store.svg";
import gplayLogo from "./img/play-store.svg";
import bbLogo from "./img/bobo-nav.svg";
import mcLogo from "./img/mclogo-nav.svg";
import journeyWithBoBo from "./img/journey-with-bobo.png";
import scheduleImg from "./img/schedule-img.png";
import fobs from "./img/fobs.png";
import copyIcon from "./img/copy-icon.svg";
import footerLogos from "./img/logos-footer.webp";
import instaIcon from "./img/instagram.svg";
import inIcon from "./img/linkedin.svg";
import checkedIcon from "./img/checked.svg";
import uncheckedIcon from "./img/unchecked.svg";

const Megeve25 = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showForm, setShowForm] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }, [copied]);

  const {
    balanceAndPin,
    isLoading,
    savingPersonalDetails,
    errorSavingPersonalDetails
  } = useSelector((state) => state.balanceAndPin);

  const { device, gettingDeviceInfo, gettingDeviceInfoError } = useSelector(
    (state) => state.hugoBoss
  );

  const prevGettingDeviceInfo = usePrevious(gettingDeviceInfo);

  useEffect(() => {
    if (prevGettingDeviceInfo && !gettingDeviceInfoError) {
      if (device?.personalDetails?.fullName && device?.personalDetails?.email) {
        setFullName(device?.personalDetails?.fullName);
        setEmail(device?.personalDetails?.email);
        setShowForm(false);
      } else {
        setShowForm(true);
      }
    }
  }, [
    device?.personalDetails?.fullName,
    device?.personalDetails?.email,
    gettingDeviceInfoError,
    prevGettingDeviceInfo
  ]);

  const prevSavingPersonalDetails = usePrevious(savingPersonalDetails);

  useEffect(() => {
    if (prevSavingPersonalDetails && !errorSavingPersonalDetails) {
      setShowForm(false);
    }
  }, [errorSavingPersonalDetails, prevSavingPersonalDetails]);

  useEffect(() => {
    dispatch(getDeviceInfo(serialNumber));
  }, [dispatch, serialNumber]);

  useEffect(() => {
    if (!balanceAndPin && serialNumber) {
      dispatch(
        getBalanceAndPin({
          serial: serialNumber,
          query: "?provider=pannovate"
        })
      );
    }
  }, [balanceAndPin, serialNumber, dispatch]);

  const validateForm = () => {
    let formErrors = {};

    const namePattern = /^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]+$/;
    if (!fullName.trim()) {
      formErrors.fullName = t("megeve25.nameRequired");
    } else if (!namePattern.test(fullName)) {
      formErrors.fullName = t("megeve25.nameError");
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim()) {
      formErrors.email = t("megeve25.emailRequired");
    } else if (!emailPattern.test(email)) {
      formErrors.email = t("megeve25.emailError");
    }

    setErrors(formErrors);

    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      dispatch(
        savePersonalDetailsAction({
          serial: serialNumber,
          details: { fullName, email }
        })
      );
    }
  };

  const SCHEDULE = [
    {
      day: t("megeve25.friday"),
      date: t("megeve25.january17"),
      events: [
        { time: "10:30", ev: t("megeve25.briefingDrivers") },
        { time: "13:30", ev: t("megeve25.departureForTour") },
        { time: "12:00 - 14:00", ev: t("megeve25.lunchUnderVIPMoet") },
        { time: "17:30", ev: t("megeve25.paradeOfCars") },
        { time: "19:30", ev: t("megeve25.openingCeremony") }
      ]
    },
    {
      day: t("megeve25.saturday"),
      date: t("megeve25.january18"),
      events: [
        { time: "10:30", ev: t("megeve25.startCompetition") },
        { time: "12:00", ev: t("megeve25.lunchUnderVIPMoet") },
        { time: "17:00", ev: t("megeve25.endCompetition") },
        { time: "20:00", ev: t("megeve25.galaDinner") }
      ]
    },
    {
      day: t("megeve25.sunday"),
      date: t("megeve25.january19"),
      events: [
        { time: "10:30", ev: t("megeve25.awardCeremony") },
        { time: "11:30", ev: t("megeve25.ridesForChildren") },
        { time: "17:00", ev: t("megeve25.endOfEvent") }
      ]
    }
  ];

  const appStoreLinks = (appStoreUrl, playStoreUrl) => {
    return (
      <AppStoreLinks>
        <img
          src={appStoreLogo}
          alt="app-store"
          onClick={() => window.open(appStoreUrl, "_blank")}
        />
        <img
          src={gplayLogo}
          alt="play-store"
          onClick={() => window.open(playStoreUrl, "_blank")}
        />
      </AppStoreLinks>
    );
  };

  if (isLoading || gettingDeviceInfo) return <Spinner />;
  return (
    <MainContainer>
      <Navbar>
        <div className="balance">
          <p>{t("megeve25.currentBalance")}</p>
          <p>
            {formattedBalance(
              balanceAndPin?.balanceDetails?.availableBalance,
              balanceAndPin?.balanceDetails?.currency
            )}
          </p>
        </div>
        <LanguageSelect />
      </Navbar>
      <div className="logo-container">
        <img src={mcLogo} alt="mc-logo" />
        <div className="separator" />
        <img src={bbLogo} alt="bb-logo" />
      </div>
      <Hero>
        <div className="img-container">
          <img src={heroImg} alt="classic cars" />
        </div>

        <BottomSection height={116} margin={90} color="#C87B35" />
      </Hero>

      <Explore>
        <div className="inner">
          <h2>{t("megeve25.explore")}</h2>
          <p>{t("megeve25.exploreIntro")}</p>
          <p>{t("megeve25.scrollBelow")}</p>
          <div className="buttons">
            <Button
              onClick={() => window.open(`http://www.bobofin.com/`, "_blank")}
            >
              {t("megeve25.exploreMore")}
            </Button>
            <Button
              secondary
              onClick={() =>
                window.open(
                  `https://s3.eu-west-2.amazonaws.com/www.digiseq.co.uk/bobo/bobo-email-brochure.pdf`,
                  "_blank"
                )
              }
            >
              {t("megeve25.boboBrochure")}
            </Button>
          </div>
        </div>
      </Explore>
      <JourneyImageContainer>
        <img src={journeyWithBoBo} alt="journey with BoBo" />
      </JourneyImageContainer>
      <YourJourney>
        <h2>{t("megeve25.journeyWithBoBo")}</h2>
        <div className="text-container">
          <p>{t("megeve25.elevateLifestyle")}</p>
          <p>
            {t("megeve25.welcomePackage1")}
            <span className="bold">{t("megeve25.welcomePackage2")}</span>
            {t("megeve25.welcomePackage3")}
          </p>
        </div>
      </YourJourney>
      <Activation>
        <h3>{t("megeve25.connectKeyfob")}</h3>
        <p>{t("megeve25.downloadApp")}</p>
        {appStoreLinks(
          "https://apps.apple.com/us/app/bourgeois-boheme/id1574430730",
          "https://play.google.com/store/apps/details?id=com.paymeswiss.bobofin"
        )}

        <div className="activation">
          <p>{t("megeve25.activationCode")}</p>
          <div
            className="code"
            onClick={() => {
              navigator.clipboard.writeText(balanceAndPin?.activationCode);
              setCopied(true);
            }}
          >
            <h3 className="activation-code">
              {balanceAndPin?.activationCode || "N/A"}
            </h3>
            <img className="copy" src={copyIcon} alt="copy-icon" />
          </div>
          {copied && (
            <p>
              <b>{t("megeve25.codeCopied")}</b>
            </p>
          )}
        </div>
        <div className="fobs">
          <img src={fobs} alt="fob Arosa" />
        </div>
      </Activation>

      <Schedule>
        <div className="img-container">
          <img src={scheduleImg} alt="schedule" />
        </div>
        <div className="header">
          <p>{t("megeve25.pilotProgram")}</p>
        </div>
        <List>
          {SCHEDULE.map((entry, i) => {
            return (
              <div key={i}>
                <ItemHeader>
                  <p className="weekday">
                    {entry.day}
                    {","}
                  </p>
                  <p>{entry.date}</p>
                </ItemHeader>
                {entry.events.map((event, j) => (
                  <Item key={j}>
                    <p className="time">{event.time}</p>
                    <p>{event.ev}</p>
                  </Item>
                ))}
              </div>
            );
          })}
        </List>
        <div className="bottom-section" />
      </Schedule>
      <JoinBoBo>
        <p>
          <span>{t("megeve25.contactSupport")}</span>
          <a href="mailto:support@bobofin.com">support@bobofin.com</a>
          <span>{t("megeve25.orViaChat")}</span>
        </p>
        <Button
          onClick={() =>
            window.open(`https://www.bobofin.com/become-a-member`, "_blank")
          }
        >
          {t("megeve25.joinBoBo")}
        </Button>
      </JoinBoBo>

      <Footer>
        <div className="socials">
          <img
            src={instaIcon}
            alt="instagram"
            onClick={() =>
              window.open(
                `https://www.instagram.com/bobo_network?igsh=MTk0N3hmZWc5dnNmZg==`,
                "_blank"
              )
            }
          />
          <img
            src={inIcon}
            alt="linkedin"
            onClick={() =>
              window.open(
                `https://www.linkedin.com/company/bourgeoisboheme/`,
                "_blank"
              )
            }
          />
        </div>
        <a
          href="https://s3.eu-west-2.amazonaws.com/www.digiseq.co.uk/bobo/tc-arosa.pdf"
          rel="noopener noreferrer"
          target="_blank"
        >
          {t("megeve25.termsAndConditions")}
        </a>
        <a
          href="https://www.bobofin.com/privacy-policy-cookies-policy"
          rel="noopener noreferrer"
          target="_blank"
        >
          {t("megeve25.privacyPolicy")}
        </a>
        <img src={footerLogos} alt="footer-logos" />

        <div className="copyToClipboard">
          <img
            onClick={() => {
              navigator.clipboard.writeText(serialNumber);
              setCopied(true);
            }}
            src={copyIcon}
            alt="copy to clipboard"
          />
          <p>ID: {serialNumber}</p>
        </div>
        {copied && (
          <p>
            <b>{t("megeve25.codeCopied")}</b>
          </p>
        )}
        <div className="version">
          <p>v.{packageJson.version}</p>
        </div>
      </Footer>
      {showForm && (
        <Alert>
          <div className="inner-container">
            <div className="form">
              <div className="nav">
                <h2 className="title">{t("megeve25.welcome")}</h2>
                <LanguageSelect />
              </div>
              <p className="subtitle">{t("megeve25.provideDetails")}</p>
              <div className="inputs">
                <div className="form-group">
                  <input
                    value={fullName}
                    id="fullName"
                    type="text"
                    onChange={(ev) => setFullName(ev.target.value)}
                    placeholder={t("megeve25.fullName")}
                  />
                  {errors.fullName && (
                    <p className="error">{errors.fullName}</p>
                  )}
                </div>
                <div className="form-group">
                  <input
                    value={email}
                    id="email"
                    type="email"
                    onChange={(ev) => setEmail(ev.target.value)}
                    placeholder={t("megeve25.email")}
                  />
                  {errors.email && <p className="error">{errors.email}</p>}
                </div>
              </div>
              <div
                className="terms"
                onClick={() => setAcceptTerms((prev) => !prev)}
              >
                <img
                  src={acceptTerms ? checkedIcon : uncheckedIcon}
                  alt="accept"
                />{" "}
                <p>
                  {t("megeve25.acceptTerms")}{" "}
                  <a
                    href="https://s3.eu-west-2.amazonaws.com/www.digiseq.co.uk/bobo/tc-arosa.pdf"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {t("megeve25.tcForm")}
                  </a>
                </p>
              </div>
              <button disabled={!acceptTerms} onClick={() => handleSubmit()}>
                {t("megeve25.proceed")}
              </button>
            </div>
            <img className="logos" src={footerLogos} alt="logos" />
          </div>
        </Alert>
      )}
    </MainContainer>
  );
};

export default Megeve25;
