import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { composeErrorMessage } from "../../utils/utils";
import twoFactorAuthService from "./twoFactorAuthService";
import authService from "../auth/authService";

const initialState = {
  twoFactorAuthCode: null,
  fetchingTwoFactorAuthCode: false,
  fetchingTwoFactorAuthCodeErrorMessage: "",
};

export const get2FaCode = createAsyncThunk(
  "twoFactorAuth/fetch2FaCode",
  async ({ serialNumber, sessionId, stickyId }, thunkAPI) => {
    try {
      const tokenResponse = await authService.authenticatePromoReadyWearable(
        serialNumber,
        sessionId,
        stickyId
      );

      const accessToken = tokenResponse.access_token;

      const response = await twoFactorAuthService.get2FaAuthCode({
        serialNumber,
        accessToken,
      });

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const twoFactorAuthSlice = createSlice({
  name: "twoFactorAuth",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(get2FaCode.pending, (state) => {
        state.fetchingTwoFactorAuthCode = true;
        state.fetchingTwoFactorAuthCodeErrorMessage = "";
      })
      .addCase(get2FaCode.fulfilled, (state, action) => {
        state.twoFactorAuthCode = action.payload;
        state.fetchingTwoFactorAuthCodeErrorMessage = "";
        state.fetchingTwoFactorAuthCode = false;
      })
      .addCase(get2FaCode.rejected, (state, action) => {
        state.twoFactorAuthCode = null;
        state.fetchingTwoFactorAuthCodeErrorMessage = action.payload;
        state.fetchingTwoFactorAuthCode = false;

      });
  },
});

export const { reset } = twoFactorAuthSlice.actions;
export default twoFactorAuthSlice.reducer;
