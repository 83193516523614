import httpClient from "../../axios";
import { ENV } from "../../env";

export const DAS_ADMIN = `https://www.${
  ENV !== "production" ? (ENV === "development" ? "dev" : ENV) + "-" : ""
}manage-mii.uk/api/das/admin/`;

export const DAS_PUBLIC_EVENTS = `https://www.${
  ENV !== "production" ? (ENV === "development" ? "dev" : ENV) + "-" : ""
}manage-mii.uk/api/das/events`;

const getPublicEvent = async (id) => {
  const response = await httpClient.get(`${DAS_PUBLIC_EVENTS}/${id}`);
  return response.data;
};

export const publicEventsManagerService = {
  getPublicEvent
};
