import { useEffect, useState } from "react";

import { InfoAlert } from "./styles";
import {
  AppStoreLinks,
  CurvelogoContainer,
  NextStepsContainer,
  StepContainer,
  StepNumber
} from "./balance.styles";

import closeIcon from "./imgs/close-icon.svg";
import headerImg from "./imgs/payment-banner.webp";
import pigIcon from "./imgs/pig-icon.svg";
import bbLogo from "./imgs/bb-logo.webp";
import copyIcon from "./imgs/copy-icon.svg";
import orSeparator from "./imgs/or-separator.svg";
import appStore from "./imgs/app-store.svg";
import googlePlay from "./imgs/google-play.svg";
import euFlag from "../../assets/eu-flag.png";
import ukFlag from "../../assets/uk-flag.png";
import curveLogo from "../../assets/curve_logo.png";
import payAnyWhereImage from "../tap2start/img/pay-anywhere-image.png";

const Balance = ({ onClose, remainingBalance, activationCode }) => {
  const [copied, setCopied] = useState(false);

  const appStoreLinks = (appStoreUrl, playStoreUrl) => {
    return (
      <AppStoreLinks>
        <img
          src={appStore}
          alt="app-store"
          onClick={() => window.open(appStoreUrl, "_blank")}
        />
        <img
          src={googlePlay}
          alt="play-store"
          onClick={() => window.open(playStoreUrl, "_blank")}
        />
      </AppStoreLinks>
    );
  };

  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }, [copied]);

  return (
    <InfoAlert>
      <div className="inner-container">
        <div className="img-container">
          <img src={headerImg} alt="header" />
          <h2>{"Payment Account"}</h2>
        </div>
        <div className="close-button" onClick={onClose}>
          <img src={closeIcon} alt="close" />
        </div>
        <div className="scroll-view">
          <div className="section">
            <p>
              {
                "A drink on the slopes or a little souvenir from downtown Kitzbühel? It’s on us!"
              }
            </p>
          </div>
          <div className="section bg-black">
            <p className="bold">{"Remaining Balance"}</p>
            <p>{"Available for spending on your fob."}</p>
            <div className="group btn-group group-reversed">
              <div className="button button-secondary">
                <p>{remainingBalance ? `${remainingBalance} €` : "N/A"}</p>
              </div>
              <div>
                <img src={pigIcon} alt="ski" />
              </div>
            </div>
          </div>
          <div className="section">
            <img className="bb-logo" src={bbLogo} alt="bbLogo" />
            <p>
              {
                "Want to continue enjoying unrivaled payment experience? Upgrade to Bourgeois Bohème wallet!"
              }
            </p>
            <p>{"Your keyfob activation code"}</p>

            <div className="activation-code">
              {!copied ? (
                <p>{activationCode ? activationCode : "N/A"}</p>
              ) : (
                <p className="copied">{"Copied to clipboard"}</p>
              )}
              <img
                onClick={() => {
                  navigator.clipboard.writeText(activationCode);
                  setCopied(true);
                }}
                src={copyIcon}
                alt="copy"
              />
            </div>

            <div
              className="button button-black button-join-bb"
              onClick={() =>
                window.open(
                  `https://bobo-website.webflow.io/become-a-member`,
                  "_blank"
                )
              }
            >
              <p>{"Join Bourgeois Bohème"}</p>
            </div>
            <img src={orSeparator} alt="or-separator" />
          </div>
          <NextStepsContainer>
            <CurvelogoContainer>
              <p className="title">Link your own card</p>
              <img className="curve-logo" src={curveLogo} alt="curve logo" />
              <div className="flags">
                <img src={euFlag} alt="eu flag" />{" "}
                <img src={ukFlag} alt="uk flag" />
              </div>
            </CurvelogoContainer>
            <StepContainer>
              <StepNumber>1</StepNumber>
              <h2>Create a Curve Account</h2>
              <p>
                Register your card details with Curve to receive a free
                compatible digital bank card.
              </p>
              <p>Download the Curve App:</p>
              {appStoreLinks(
                "https://apps.apple.com/gb/app/curve-supercharge-your-money/id1049397112",
                "https://play.google.com/store/apps/details?id=com.imaginecurve.curve.prd"
              )}
            </StepContainer>
            <StepContainer>
              <StepNumber>2</StepNumber>
              <p>
                Follow the instructions on the Manage-Mii app and use your new
                Curve Card details when prompted.
              </p>
              {appStoreLinks(
                "https://apps.apple.com/app/manage-mii/id6446908503",
                "https://play.google.com/store/apps/details?id=com.digiseq.managemii"
              )}
            </StepContainer>
            <StepContainer>
              <StepNumber>3</StepNumber>
              <h2>Pay Anywhere!</h2>
              <p>
                Tap your wearable at any card terminal where Mastercard
                Contactless is enabled.
              </p>
              <img
                className="pay-anywhere"
                src={payAnyWhereImage}
                alt="pay anywhere"
              />
            </StepContainer>
          </NextStepsContainer>
        </div>
      </div>
    </InfoAlert>
  );
};

export default Balance;
