import axios from "axios";
const API_URL =
  "https://api.open-meteo.com/v1/forecast?latitude=47.4464&longitude=12.3922&daily=temperature_2m_min,temperature_2m_max,weathercode&timezone=auto";

const getWeatherData = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};

const kitzbuhelService = {
  getWeatherData
};

export default kitzbuhelService;
