import styled, { css } from "styled-components";

const columnCenter = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const section = css`
  padding: 24px 16px;
  background-color: #282827;
  border-radius: 6px;
  gap: 16px;
  align-self: stretch;
`;

export const MainContainer = styled.div`
  ${columnCenter}
  width: 100%;
  max-width: 480px;
  margin: auto;
  min-height: 100vh;
  background-color: #000000;
  display: flex;
  padding: 18px 16px 24px 16px;
  gap: 16px;
  align-self: stretch;

  p,
  button {
    text-align: center;
    font-family: "Mark For MC Narrow W00 Light";
    font-size: 16px;
    font-style: normal;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
    color: #ccc;
  }

  h1,
  h2 {
    color: #ccc;
    text-align: center;
    font-family: "Mark For MC Narrow W00 Book";
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 152.381% */
  }
`;

export const Intro = styled.div`
  ${columnCenter}
  ${section}
  background-color: #515150;
  img {
    width: 136px;
    height: 45px;
  }
`;

export const Balance = styled.div`
  ${columnCenter}
  ${section}
  position: relative;
  img {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 35px;
    height: 35px;
    cursor: pointer;
  }
  p {
    font-size: 32px;
  }
`;

export const Schedule = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  ${section}

  .schedule-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  h2 {
    align-self: center;
  }

  p {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    text-align: left;
  }

  .time {
    color: #f37439;
    min-width: 70px;
  }
  .schedule-title {
    width: 100%;
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    margin-bottom: 8px;
  }
`;

export const LiveFeed = styled.div`
  ${columnCenter}
  ${section}


  .round-container {
    width: 100%;
    align-self: stretch;
    display: flex;
    background-color: #505050;
    padding: 10px 16px;
    border-radius: 6px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
  .game-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    span {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ccc;
      font-size: 12px;
    }
    .current {
      background-color: #343434;
      border-radius: 6px;
      padding: 4px 8px;
    }

    .score {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 4px;
    }
  }
  .player {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 23px;
      height: 23px;
    }
    p {
      font-size: 10px;
      padding: 0;
      margin-top: 4px;
      line-height: 14px;
    }
  }
`;

export const AoMap = styled.div`
  ${columnCenter}
  ${section}
`;

export const Wearable = styled.div`
  ${columnCenter}
  ${section}
  .wearable-img {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

export const Store = styled.div`
  ${columnCenter}
  ${section}
`;

export const MastercardCam = styled.div`
  ${columnCenter}
  ${section}
`;

export const Priceless = styled.div`
  ${columnCenter}
  ${section}
`;

export const Donate = styled.div`
  ${columnCenter}
  justify-content: flex-end;
  padding-bottom: 10px;
  width: 100%;
  height: 146px;
  background-image: ${({ bgImg }) => `url(${bgImg})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const Footer = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 32px 16px 24px;
  gap: 8px;
  .logo {
    width: 148px;
    height: 38px;
    display: flex;
    justify-content: flex-start;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin-left: -16px;
    }
  }
  .copyToClipboard {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    img {
      cursor: pointer;
      color: #ffffff;
      width: 20px;
      height: 20px;
    }
  }
  .version {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
`;

export const Button = styled.button`
  ${columnCenter}
  padding: 8px 0;
  width: 126px;
  color: #fff !important;
  border-radius: 360px;
  background: #cf4500;
  border: none;
  cursor: pointer;
`;

export const Game = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
`;

export const Alert = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  background: rgba(9, 10, 15, 0.64);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 20px;

  .info-alert-content {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 450px;
    width: 90%;

    background: #fff;
    padding: 48px 16px 32px;
    border-radius: 6px;
    top: calc(50 / 100 * 100vh);
    transform: translateY(-50%);

    p,
    h2 {
      color: #000;
    }
    .close-btn {
      position: absolute;
      top: 16px;
      right: 16px;
      cursor: pointer;
      width: 24px;
      height: 24px;
    }
  }
`;
