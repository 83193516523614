import axios from "axios";
import { ENV } from "../../env";

export const DAS_API_URL = `https://www.${
  ENV !== "production" ? (ENV === "development" ? "dev" : ENV) + "-" : ""
}manage-mii.uk/api/das`;

const getSportsData = async ({ sportId, leagueId }) => {
  const response = await axios.get(
    DAS_API_URL + `/sports/${sportId}/inplay?leagueId=${leagueId}`
  );
  return response.data;
};

const sportsService = {
  getSportsData
};

export default sportsService;
