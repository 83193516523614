import styled, { css } from "styled-components";

const columnCenter = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  margin: auto;
  min-height: 100vh;
  background-color: #f6f6f7;

  p {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
    color: #202020;
  }

  h1,
  h2 {
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    color: #202020;
    letter-spacing: 0.5px;
  }
`;

export const Navbar = styled.nav`
  background-color: #fff;
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .balance {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: flex-end;
    p {
      font-weight: 700;
    }
  }
  img {
    width: auto;
    height: 32px;
    object-fit: contain;
  }
  border-bottom: 1px solid #e0e0e0;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px 16px;
`;

export const Wearable = styled.div`
  margin: 24px auto;
  border-radius: 24px;
  background: #fff;

  width: 50%;
  height: 50%;
  filter: drop-shadow(4px 8px 32px rgba(0, 0, 0, 0.25));

  img {
    width: 100%;
    transform: scale(0.9);
    height: auto;
    object-fit: contain;
    aspect-ratio: 1;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 24px;
  background-color: #fff;
  border-radius: 16px;
  gap: 12px;

  a {
    font-weight: bold;
    text-decoration: underline;
    color: #626262;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
    letter-spacing: 0.5px;
    cursor: pointer;
  }
  .emphasized {
    color: #ff6400;
  }
  .emphasized-2 {
    color: #06caaf;
  }
`;

export const Footer = styled.footer`
  background-color: #fff;
  ${columnCenter}
  padding: 32px 16px;
  margin-top: 16px;
  gap: 16px;

  img {
    height: 30px;
    width: auto;
  }

  p {
    color: black;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
  }

  .copyToClipboard {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;

    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
  li {
    list-style: none;
  }
`;
