import styled from "styled-components";
import { createGlobalStyle } from "styled-components";

import MarkForMCNarrowBold from "../fonts/MarkForMCNarrowW00-Bold.woff";
import MarkForMCNarrowWBook from "../fonts/MarkForMCNarrowW00-Book.woff";
import MarkForMCNarrowLight from "../fonts/MarkForMCNarrowW00-Light.woff";
import MarkForMCNarrowHeavy from "../fonts/MarkForMCNarrowW00-Heavy.woff";

export const MasterCardDemoFonts = createGlobalStyle`

@font-face {
  font-family: 'Mark For MC Narrow W00 Heavy';
  src: url(${MarkForMCNarrowHeavy}) format('woff');
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 36px;
}

@font-face {
  font-family: 'Mark For MC Narrow W00 Bold';
  src: url(${MarkForMCNarrowBold}) format('woff');
  font-style: normal;
  font-weight: 450;
  font-size: 18px;
  line-height: 24px;
}

@font-face {
  font-family: 'Mark For MC Narrow W00 Book';
  src: url(${MarkForMCNarrowWBook}) format('woff');
  font-style: normal;
  font-weight: 450;
  font-size: 18px;
  line-height: 24px;
}

@font-face {
  font-family: 'Mark For MC Narrow W00 Light';
  src: url(${MarkForMCNarrowLight}) format('woff');
  font-weight: 350;
  font-size: 30px;
  line-height: 32px;
  letter-spacing: 1px;
}
`;

export const MainContainer = styled.section`
  display: flex;
  flex-direction: column;
  max-width: 480px;
  min-height: 100vh;
  width: 100%;
  margin: auto;
  background-color: #f5f5f5;
  color: black;
`;

export const SpinnerContainer = styled.section`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background-color: #141413;
  height: 64px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  img {
    max-width: 40;
    max-height: 40;
  }
`;

export const BalanceContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 40px;
`;

export const ViewPinButton = styled.button`
  background-color: black;
  cursor: pointer;
  border: 1px solid white;
  border-radius: 90px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;
  color: white;
  font-family: "Arimo";
  font-size: 16px;
  font-weight: 400;
  color: white;
`;

export const OpenDetailsButton = styled.button`
  height: 24px;
  cursor: pointer;
  padding: 4px;
  border-radius: 0px;
  background-color: white;

  border: 0ch;
  color: white;
`;

export const Banner = styled.section`
  img {
    margin-bottom: -5px;
    width: 100%;
    height: 230px;
  }
`;

export const TitleTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: black;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 24px;
  padding-bottom: 24px;
  background-color: #303033;
`;

export const TitleText = styled.section`
  font-family: "Mark For MC Narrow W00 Light";
  font-size: 40px;
  font-weight: 400;
  color: #ffffff;
`;

export const SubtitleText = styled.section`
  margin-top: 8px;
  font-family: "Mark For MC Narrow W00 Book";
  font-size: 18px;
  font-weight: 350;
  color: #ffffff;
`;

export const OptionContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
  background-color: white;
  border-radius: 16px;
  color: black;
  padding-bottom: 24px;
`;

export const OptionHeader = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 32px;
  padding-bottom: 8px;
  img {
    max-width: 112px;
    max-height: 32px;
  }
`;

export const OptionTextContainer = styled.section`
  padding-top: 16px;
  padding-left: 24px;
  padding-right: 24px;
  span {
    font-family: "Mark For MC Narrow W00 Book";
    font-size: 18px;
    color: black;
  }
  b {
    font-family: "Mark For MC Narrow W00 Bold";
    font-size: 18px;
    color: black;
  }
`;

export const OptionText = styled.section`
  font-family: "Mark For MC Narrow W00 Book";
  font-size: 18px;
  color: black;
  margin-top: 16px;
  margin-left: 24px;
  margin-right: 40px;
`;

export const IssuersContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 40px;
  justify-content: flex-start;
  align-self: center;
  margin: 24px;
  //  width: 92%;
  background-color: white;
  border-radius: 16px;
  border-color: red;
  padding-right: 40px;
`;

export const IssuerContainer = styled.section`
  display: flex;
  justify-content: center;
  height: 36px;
  width: 73px;
  border-radius: 6px;
  color: white;
  border: 1px solid #aeb1b8;
  margin: 4px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const OpenAppButtonContainer = styled.div`
  padding: 16px;
`;

export const Button = styled.button`
  background: #141413;
  font-family: "Mark For MC Narrow W00 Bold";
  border: none;
  border-radius: 90px;
  height: 56px;
  width: 100%;
  color: white;

  margin: 10px 0;
  text-align: center;
  font-weight: 700;
  font-size: 1.125rem;
  cursor: pointer;
  &:hover {
    background-color: #282a2d;
  }
  &:active {
    transform: scale(0.98);
  }
`;

export const AppStoresContainer = styled.section`
  display: flex;
  flex-direction: row;
  margin-left: 24px;

  img {
    max-width: 131px;
    max-height: 100%;
    margin-right: 8px;
  }
`;

export const BalanceTitle = styled.section`
  font-family: "Arimo";
  font-size: 18px;
  font-weight: 400;
  color: white;
`;

export const BrowseWearablesContainer = styled.section`
  display: flex;
  flex-direction: row;
  font-family: "Mark For MC Narrow W00 Book";
  font-size: 18px;
  font-weight: 350;
  color: #ffffff;
  text-decoration: underline;

  img {
    margin-top: 4px;
    margin-left: 4px;
    color: white;
    max-width: 24px;
    max-height: 24px;
  }
`;

export const Divider = styled.div`
  border-bottom: 0.5px solid #aeb1b8;
  margin-top: 8px;
  margin-bottom: 8px;
`;

export const Footer = styled.div`
  display: flex;
  align-items: baseline;
  background-color: #141413;
  height: 120px;
  padding: 0 12px;
  display: flex;
  img {
    margin-top: 16px;
    max-width: 40;
    max-height: 40;
  }
`;
