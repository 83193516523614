import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { StyledMain } from "../../styled/StyledMain";
import { MainContainer } from "../../styled/StyledMainContainer";

import { StyledContainer } from "./styled/Container";
import { SearchInput, Table, Th, Td, DownloadContainer } from "./styled/Table";

import { getEvent } from "../../features/event-manager/eventManagerSlice";

import { convertArrayToCSV, downloadCSV } from "../../utils/utils";
import downloadIcon from "./img/download.svg";

import CoachTrackerProtectedRoute from "../../components/CoachTrackerProtectedRoute";
import Spinner from "../../components/Spinner";
import NavBarComponent from "../campaign-manager/components/NavBar";
import { useParams } from "react-router-dom";
import { getInfoCollectorDevices } from "../../features/campaign-manager/infoCollectorSlice";

const InfoCollectorAdmin = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const { devices, fetchingDevices } = useSelector(
    (state) => state.infoCollector
  );

  const { event, isLoading } = useSelector((state) => state.events);

  useEffect(() => {
    if (!event) {
      dispatch(getEvent(params.eventId));
      dispatch(getInfoCollectorDevices(params.eventId));
    }
  }, [event, dispatch, params.eventId]);

  const [searchTerms, setSearchTerms] = useState({
    serialNumber: "",
    firstName: "",
    lastName: "",
    outgoingCoach: "",
    returningCoach: "",
  });

  const handleSearch = (e) => {
    const { name, value } = e.target;
    setSearchTerms((prev) => ({ ...prev, [name]: value }));
  };

  const filteredData = devices.filter((passenger) => {
    const match = (field, term) => {
      if (!term) return true;
      field = field?.toString().toLowerCase() || ""; // Ensure field is stringified
      term = term.toLowerCase();
      return field.includes(term);
    };

    const fixedFieldsMatch =
      match(passenger.serialNumber, searchTerms.serialNumber) &&
      match(passenger.personalDetails?.firstName, searchTerms.firstName) &&
      match(passenger.personalDetails?.lastName, searchTerms.lastName);

    const dynamicFieldsMatch = event?.infoCollectorEventData?.fields.every(
      (field) => {
        const selectedChoice = passenger.eventData?.[
          event.id
        ]?.infoCollector?.selectedChoices?.find(
          (choice) => choice.fieldId === field.id
        );

        const selectedOption = field.options.find(
          (option) => option.id === selectedChoice?.selectedOptionId
        );

        return match(selectedOption?.item, searchTerms[field.id]);
      }
    );

    return fixedFieldsMatch && dynamicFieldsMatch;
  });

  const exportToCSV = () => {
    const headers = [
      "Serial Number",
      "First Name",
      "Last Name",
      ...event?.infoCollectorEventData?.fields.map((field) => field.title),
    ];

    const csvData = devices.map((device) => {
      const dynamicFields = event?.infoCollectorEventData?.fields.map(
        (field) => {
          const selectedChoice = device.eventData?.[
            event.id
          ]?.infoCollector?.selectedChoices?.find(
            (choice) => choice.fieldId === field.id
          );

          const selectedOption = field.options.find(
            (option) => option.id === selectedChoice?.selectedOptionId
          );
          return selectedOption ? selectedOption.item : "-";
        }
      );

      console.log(dynamicFields);

      return [
        device.serialNumber || "",
        device.personalDetails?.firstName || "",
        device.personalDetails?.lastName || "",
        ...dynamicFields,
      ];
    });

    const csvArray = [headers, ...csvData];
    const csvContent = convertArrayToCSV(csvArray);

    downloadCSV(csvContent, "info_collector.csv");
  };

  if (fetchingDevices || isLoading) return <Spinner />;

  return (
    <StyledMain grayBg>
      <CoachTrackerProtectedRoute />
      <NavBarComponent />
      <MainContainer>
        <StyledContainer>
          <h2>Info Collector</h2>
          <h2>{event?.title}</h2>

          <div>
            <DownloadContainer>
              <p>{filteredData.length} devices(s) found</p>
              <img
                src={downloadIcon}
                alt="download"
                onClick={() => exportToCSV()}
              />
            </DownloadContainer>
            <Table>
              <thead>
                <tr>
                  <Th>Serial Number</Th>
                  <Th>First Name</Th>
                  <Th>Last Name</Th>
                  {event?.infoCollectorEventData?.fields.map((field) => (
                    <Th key={field.id}>{field.title}</Th>
                  ))}
                </tr>
                <tr>
                  <Td>
                    <SearchInput
                      type="text"
                      name="serialNumber"
                      placeholder="Search Serial Number"
                      value={searchTerms.serialNumber}
                      onChange={handleSearch}
                    />
                  </Td>
                  <Td>
                    <SearchInput
                      type="text"
                      name="firstName"
                      placeholder="Search First Name"
                      value={searchTerms.firstName}
                      onChange={handleSearch}
                    />
                  </Td>
                  <Td>
                    <SearchInput
                      type="text"
                      name="lastName"
                      placeholder="Search Last Name"
                      value={searchTerms.lastName}
                      onChange={handleSearch}
                    />
                  </Td>
                  {event?.infoCollectorEventData?.fields.map((field) => (
                    <Td key={field.id}>
                      <SearchInput
                        type="text"
                        name={field.id}
                        placeholder={`Search ${field.title}`}
                        value={searchTerms[field.id] || ""}
                        onChange={handleSearch}
                      />
                    </Td>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredData.map((item) => (
                  <tr key={item.serialNumber}>
                    <Td>{item.serialNumber}</Td>
                    <Td>{item.personalDetails?.firstName}</Td>
                    <Td>{item.personalDetails?.lastName}</Td>
                    {event?.infoCollectorEventData?.fields.map((field) => {
                      const selectedChoice = item.eventData?.[
                        event.id
                      ]?.infoCollector?.selectedChoices?.find(
                        (choice) => choice.fieldId === field.id
                      );

                      const selectedOption = field.options.find(
                        (option) =>
                          option.id === selectedChoice?.selectedOptionId
                      );

                      return (
                        <Td key={field.id}>
                          {selectedOption ? selectedOption.item : "-"}
                        </Td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </StyledContainer>
      </MainContainer>
    </StyledMain>
  );
};

export default InfoCollectorAdmin;
