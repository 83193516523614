import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";

import { get2FaCode } from "../../features/2fa-auth/twoFactorAuthSlice";
import usePrevious from "../../hooks/usePrevious";

import {
  MainContainer,
  SpinnerContainer,
  Header,
  TitleText,
  CodeText,
  TimerText,
  ExpiredText,
} from "./styled/TwoFactorAuthCode";

import Spinner from "../../components/Spinner";

import digiseqLogo from "./img/digiseq-logo.svg";
import { secondsRemainingFromDate } from "../../utils/dateTime";

const TwoFactorAuthCode = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");
  const sessionId = searchParams.get("sessionId");
  const stickyId = searchParams.get("stickyId");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    twoFactorAuthCode,
    fetchingTwoFactorAuthCode,
    fetchingTwoFactorAuthCodeErrorMessage,
  } = useSelector((state) => state.twoFactorAuth);

  const prevFetchingTwoFactorAuthCode = usePrevious(fetchingTwoFactorAuthCode);

  const [secondsLeft, setSecondsLeft] = useState(60);

  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    const params = currentUrl.searchParams;

    params.delete("sessionId");
    params.delete("stickyId");

    const newUrl = `${currentUrl.pathname}?${params.toString()}`;
    navigate(newUrl, { replace: true });
  }, [navigate]);

  useEffect(() => {
    dispatch(get2FaCode({ serialNumber, sessionId, stickyId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (prevFetchingTwoFactorAuthCode && twoFactorAuthCode) {
      setSecondsLeft(
        secondsRemainingFromDate(
          twoFactorAuthCode?.twoFactorVerificationCode?.expiryDate
        )
      );
      startTimer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevFetchingTwoFactorAuthCode, twoFactorAuthCode]);

  const startTimer = () => {
    if (secondsLeft <= 0) return;

    const timerId = setInterval(() => {
      setSecondsLeft((prev) => {
        const newSecondsLeft = Math.max(0, prev - 1);
        if (newSecondsLeft <= 0) {
          clearInterval(timerId);
        }
        return newSecondsLeft;
      });
    }, 1000);

    return () => clearInterval(timerId);
  };

  if (fetchingTwoFactorAuthCode)
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );

  if (fetchingTwoFactorAuthCodeErrorMessage)
    return (
      <MainContainer>
        <Header>
          <img src={digiseqLogo} alt="logo" />
        </Header>

        <ExpiredText>Failed to retrieve 2FA code, please try again</ExpiredText>
      </MainContainer>
    );

  return (
    <MainContainer>
      <Header>
        <img src={digiseqLogo} alt="logo" />
      </Header>

      {twoFactorAuthCode?.twoFactorVerificationCode && secondsLeft !== 0 && (
        <>
          <TitleText>2FA Code</TitleText>
          <CodeText>
            {twoFactorAuthCode?.twoFactorVerificationCode?.code}
          </CodeText>
        </>
      )}
      <div>
        {twoFactorAuthCode && secondsLeft > 0 ? (
          <TimerText>Code expires in {secondsLeft} seconds</TimerText>
        ) : (
          <ExpiredText>2FA Code Expired!</ExpiredText>
        )}
      </div>
    </MainContainer>
  );
};

export default TwoFactorAuthCode;
