import React from "react";
import { Alert } from "./styles";

import closeIcon from "./imgs/close-icon.svg";

const InfoAlert = ({ onClose, panNumber, expiryDate }) => {
  return (
    <Alert>
      <div className="info-alert-content">
        <h2>{`PAN: ****${panNumber}`}</h2>
        <p>{`Expiry date: ${expiryDate}`}</p>
        <img
          src={closeIcon}
          alt="close"
          className="close-btn"
          onClick={onClose}
        />
      </div>
    </Alert>
  );
};

export default InfoAlert;
