import styled, { css } from "styled-components";

const columnCenter = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  margin: auto;
  min-height: 100vh;
  background-color: #000000;

  p {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
    color: #ffffff;
  }

  h2 {
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    color: #ffffff;
  }
`;

export const Navbar = styled.nav`
  background-color: #282827;
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .balance {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: flex-end;
    p {
      font-weight: 700;
    }
  }
  img {
    width: auto;
    height: 32px;
    object-fit: contain;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 16px;
`;

export const HeroContainer = styled.div`
  ${columnCenter}
  padding: 32px 24px;
  border-radius: 16px;
  background-color: #282827;
  gap: 12px;

  p {
    color: white;
  }
`;

export const WearableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #282827;
  border-radius: 16px;
  padding: 16px;
  margin: 24px 16px;
`;

export const Wearable = styled.div`
  margin: 24px auto;
  border-radius: 24px;
  background: #fff;

  width: 50%;
  height: 50%;
  filter: drop-shadow(4px 8px 32px rgba(0, 0, 0, 0.25));

  img {
    width: 100%;
    transform: scale(0.9);
    height: auto;
    object-fit: contain;
    aspect-ratio: 1;
  }
`;

export const AfterEventContainer = styled.div`
  margin-top: -1px;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px 0;
  border-radius: 16px;
  background-color: #282827;
  h2 {
    margin-top: 16px;
    line-height: 40px;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 24px;
  background-color: #282827;
  border-radius: 16px;
  gap: 12px;

  a {
    font-weight: bold;
    text-decoration: underline;
    color: #ffffff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
    letter-spacing: 0.5px;
    cursor: pointer;
  }
  .emphasized {
    color: #ff6400;
  }
`;

export const StepContainer = styled.div`
  padding: 32px 16px;

  p {
    margin-top: 16px;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
    columns: #626262;
  }
  .pay-anywhere {
    margin: 24px auto;
    width: 100%;
    object-fit: contain;
  }
  .step-title {
    color: #ffffff;
    font-family: "Mark For MC Narrow W00 Book";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0px;
  }
`;

export const CurvelogoContainer = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 16px;

  .separator {
    height: 25px;
    object-fit: cover;
    width: 100%;
  }
  .title {
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    color: #ffffff;
  }
  .curve-logo-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 8px;
  }

  .curve-logo {
    height: 32px;
    width: 152px;
  }
  .flags {
    display: flex;
    gap: 8px;
    img {
      height: 26px;
    }
  }
  .expand-collapse {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
  }
`;

export const StepNumber = styled.div`
  display: flex;
  width: 40px;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 360px;
  background: #282827;
  border: 1px solid #ffffff;
  color: #ffffff;
`;

export const AppStoreLinks = styled.section`
  img {
    cursor: pointer;
    margin-top: 24px;
    margin-right: 8px;
  }
`;

export const Footer = styled.footer`
  background-color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 32px 16px;
  margin-top: 16px;
  gap: 16px;
  .emphasized {
    color: #ff6400;
  }
  p {
    color: white;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
  }

  .copyToClipboard {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;

    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
  li {
    list-style: none;
  }

  .links {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .socials {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }
`;
