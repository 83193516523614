import styled from "styled-components";
import introBackground from "./imgs/intro.webp";
import skiingFooter from "./imgs/skiing-footer.webp";
const textHeavy = {
  fontFamily: "Mark For MC Narrow W00 Heavy",
  fontWeight: 800,
  color: "#000000"
};

const textLight = {
  fontFamily: "Mark For MC Narrow W00 Light",
  fontWeight: 350,
  color: "#000000"
};

const textBold = {
  fontFamily: "Mark For MC Narrow W00 Bold",
  fontWeight: 700,
  color: "#000000"
};

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  margin: auto;
  min-height: 100vh;
  background-color: #ffffff;

  & h1 {
    ${textHeavy}
    font-size: 38px;
    line-height: 40px;
  }

  & h2 {
    ${textBold}
    font-size: 28px;
    line-height: 36px;
  }

  & h3,
  p {
    ${textLight}
    font-size: 16px;
    line-height: 25px;
  }
  & .bold,
  h3 {
    ${textBold}
  }
  h3 {
    color: #f37439;
  }

  a {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const columnCenter = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center"
};

const columnLeft = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center"
};

const rowSpaceBetween = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center"
};

export const Separator = styled.div`
  width: 56%;
  height: 2px;
  background-color: #f37439;
  align-self: ${({ isLeft }) => (isLeft ? "flex-start" : "flex-end")};
`;

export const NavBar = styled.div`
  ${rowSpaceBetween}
  padding: 16px;
  background-color: #000000;
  ${columnCenter}
`;

export const IntroContainer = styled.div`
  ${columnCenter}
  .image-container {
    padding: 8px 16px;
    width: 100%;
    height: 100%;
    background: url(${introBackground}) no-repeat center center;
    background-size: cover;
    aspect-ratio: 393/233;

    .new-line {
      display: block;
    }

    h1 {
      text-align: right;
      color: #ffffff;
    }
  }
  .text-container {
    padding: 20px;
  }
`;

export const ScheduleContainer = styled.div`
  background-color: #000000;
  ${columnLeft}
  padding: 20px;
  gap: 16px;
  margin-bottom: 16px;
  h2,
  p {
    color: #ffffff;
  }
  .time {
    color: #f37439;
  }

  .text-container,
  .schedule-list {
  }
  .bg-white {
    background-color: #ffffff;
    p {
      color: #000000;
    }
  }
`;

export const ScheduleInfoContainer = styled.div`
  ${columnLeft}
  padding: 20px;
  gap: 16px;
`;

export const ScheduleItem = styled.div`
  ${columnLeft}

  .schedule-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .time {
      color: #f37439;
    }
  }
  /* .schedule-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 4px;
  } */
`;

export const DiscoverContainer = styled.div`
  ${columnLeft}
  padding: 20px;
  gap: 20px;

  .items-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
`;

export const DiscoverItem = styled.div`
  ${columnCenter}

  img {
    width: 100%;
    height: 100%;
  }
`;

export const RacesContainer = styled.div`
  ${columnLeft}
  padding: 24px;
  gap: 12px;

  .itinerary-container {
    ${columnLeft}
    gap:8px;
  }

  .itinerary-item {
    .time {
      color: #f37439;
    }
    .time-event {
      ${rowSpaceBetween}
      gap: 4px;
    }
  }

  h3 {
    color: #000;
  }
`;

export const GetToKnowContainer = styled.div`
  ${columnLeft}
  background-color: #000000;
  p,
  .bold,
  h2 {
    color: #ffffff;
  }
  img {
    width: 100%;
    height: 100%;
  }

  .text-container {
    padding: 20px;
    ${columnLeft}
    gap:20px;
  }
`;

export const LanguageGuideContainer = styled.div`
  ${columnLeft}
  padding: 20px;
  gap: 12px;

  .bold {
    color: #f37439;
  }
`;

export const FooterContainer = styled.div`
  ${columnLeft}
  padding: 32px 16px 24px;
  gap: 12px;
  background-color: #000000;
  p,
  a {
    color: #ffffff;
    text-decoration: none;
  }
  h3 {
    color: #fff;

    font-feature-settings: "liga" off, "clig" off;
    /* Headline/Small */
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  }
  img {
    width: auto;
    height: 36px;
    object-fit: cover;
  }

  .copyToClipboard {
    ${rowSpaceBetween}
    gap: 8px;
    img {
      cursor: pointer;
      color: #ffffff;
      width: 20px;
      height: 20px;
    }
  }
  .version {
    ${rowSpaceBetween}
    gap: 4px;
  }
`;

export const InfoAlert = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  background: rgba(9, 10, 15, 0.64);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 20px;

  .error-message {
    color: orange !important;
  }

  .inner-container {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 450px;
    width: 90%;
    height: 90vh;
    background-color: #ffffff;
    z-index: 9999;
    ${columnLeft}
    justify-content: flex-start;
    /* overflow-y: auto; */

    .img-container {
      position: relative;
      background-color: rgba(9, 10, 15, 0.64);
      width: 100%;
      height: 126px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      h2 {
        position: absolute;
        bottom: 16px;
        left: 16px;
        color: #ffffff;
      }
    }

    .close-button {
      position: absolute;
      top: 16px;
      right: 16px;
      width: 16px;
      height: 16px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .section {
      padding: 20px;
      text-align: left;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .group {
        width: 100%;
        ${rowSpaceBetween}

        .icon {
          width: 50px;
          height: 50px;
        }

        .icon-processing {
          width: 50px;
          height: 50px;
          animation: spin 2s linear infinite;
        }

        @keyframes spin {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      }
      .btn-group {
        gap: 40px;
        margin-top: 10px;
      }
      .group-reversed {
        ${rowSpaceBetween}
        flex-direction: row-reverse;
      }
      .bb-logo {
        width: 126px;
        height: 40px;
      }
      .activation-code {
        width: 100%;
        padding: 12px 16px;
        margin-bottom: 24px;
        ${rowSpaceBetween}
        border-radius: 5px;
        border: 1px solid #000000;
        p {
          font-family: "Inter";
          color: #000000;
          font-size: 28px;
          font-weight: 700;
          line-height: 36px;
        }
      }

      .mm-icon {
        width: 40px;
        height: 40px;
      }
      .stores {
        display: flex;
        gap: 8px;
        padding-bottom: 32px;
      }

      .faq-container {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
      .faq-item {
        ${rowSpaceBetween}
        gap: 4px;
        img {
          cursor: pointer;
        }
        border-bottom: 1px solid #000000;
      }

      .faq-no-border {
        border-bottom: none;
      }
    }

    .hotel-section {
      padding: 0px;
      flex-direction: row;
      gap: 12px;
      img {
        aspect-ratio: 178/116;
        width: 50%;
        object-fit: cover;
      }

      p {
        font-size: 12px;
        line-height: 16px;
      }

      .hotel-info {
        display: flex;
        flex-direction: column;
        gap: 4px;
        justify-content: center;
      }
    }

    .bg-black {
      background-color: #000000;
      p {
        color: #ffffff;
      }
    }
    .error,
    .error-message {
      color: red;
    }

    .button {
      border-radius: 5px;
      background: #fd661b;
      height: 35px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        color: #ffffff;
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
      }
    }

    .button-secondary {
      background: #000000;
      p {
        color: #fd661b;
      }
      border: 1px solid #fd661b;
    }

    .button-black {
      background: #000000;
      p {
        color: #ffffff;
      }
    }

    .button-fixed {
      max-width: 162px;
    }

    .button-join-bb {
      max-width: 200px;
      margin-bottom: 16px;
    }
    .button-disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    .bg-skiing-footer {
      background-image: url(${skiingFooter});
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      aspect-ratio: 354/202;
      padding: 20px;
      ${columnLeft}
      gap:40px;
      p {
        color: #ffffff;
        text-align: left;
        font-weight: 700;
        font-size: 16px;
        line-height: 25px;
      }
    }
  }

  .scroll-view {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
`;
