import styled from "styled-components";

export const MainContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  align-self: center;
  max-width: 480px;
  min-height: 100vh;
  width: 100%;
  margin: auto;

  background: black;

  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat, repeat-y;
`;

export const SpinnerContainer = styled.section`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
  img {
    max-width: 100px;
    max-height: 100px;
  }
`

export const TitleText = styled.section`
  font-family: "Gotham Rounded Light";
  font-size: 24px;
  font-weight: 200;
  text-align: center;
  color: white;
  margin-top: 120px;
 // margin-bottom: 20px;
`;

export const CodeText = styled.section`
  font-family: "Gotham Rounded Bold";
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 24px;
  text-align: center;
  color: white;
  margin-top: 8px;
  margin-bottom: 20px;
`;

export const TimerText = styled.section`
  font-family: "Gotham Rounded Light";
  font-size: 24px;
  font-weight: 200;
  text-align: center;
  color: white;
  margin-top: 120px;
`;

export const ExpiredText = styled.section`
  font-family: "Gotham Rounded Light";
  font-size: 24px;
  font-weight: 200;
  text-align: center;
  color: white;
  margin-top: 120px;
  margin-left: 16px;
  margin-right: 16px;
`;