import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getBalanceAndPin } from "../../features/balance-and-pin/balanceAndPinSlice";
import { getDasDeviceInfo } from "../../features/das-device-manager/dasDeviceManagerSlice";
import { getPublicEventAction } from "../../features/public-events-manager/publicEventsManagerSlice";
import { getWeatherDataAction } from "../../features/kitzbuhel/kitzbuhelSlice";
import { activateTicket } from "../../features/hugo-boss/hugoBossSlice";

import usePrevious from "../../hooks/usePrevious";
import Skiing from "./Skiing";
import Balance from "./Balance";
import YourStay from "./YourStay";
import Memories from "./Memories";
import WeatherWidget from "./WeatherWidget";
import Spinner from "../../components/Spinner";

import {
  MainContainer,
  NavBar,
  IntroContainer,
  ScheduleContainer,
  DiscoverContainer,
  RacesContainer,
  GetToKnowContainer,
  LanguageGuideContainer,
  FooterContainer,
  Separator,
  ScheduleItem,
  ScheduleInfoContainer,
  DiscoverItem
} from "./styles";

import logo from "./imgs/nav-logos.svg";
import yourStay from "./imgs/your-stay.webp";
import skiing from "./imgs/skiing.webp";
import balance from "./imgs/balance.webp";
import memories from "./imgs/memories.webp";
import getToKnow from "./imgs/get-to-know.webp";
import localAttractions from "./imgs/local-attractions.webp";
import poweredBy from "./imgs/digiseq-logo.svg";
import paymentServices from "./imgs/payme-swiss.webp";
import copyIconWhite from "./imgs/copy-icon-white.svg";

import packageJson from "../../../package.json";

const Kitzbuhel25 = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");
  const eventId = searchParams.get("eventId");

  const [copied, setCopied] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  const dispatch = useDispatch();
  const { balanceAndPin, isLoading } = useSelector(
    (state) => state.balanceAndPin
  );
  const { dasDeviceInfo, gettingDasDeviceInfo, errorGettingDasDeviceInfo } =
    useSelector((state) => state.dasDevices);
  const { publicEvent, gettingPublicEvent, errorGettingPublicEvent } =
    useSelector((state) => state.publicEvents);

  const { infoCollectorEventData } = publicEvent || {};

  const { weatherData, loadingWeather, errorWeather } = useSelector(
    (state) => state.kitzbuhel
  );

  const { activatingTicket, activatingTicketError } = useSelector(
    (state) => state.hugoBoss
  );

  const prevActivatingTicket = usePrevious(activatingTicket);
  const prevGettingDasDeviceInfo = usePrevious(gettingDasDeviceInfo);

  console.log({ errorGettingPublicEvent });

  const getSelectedOptionId = (fieldId) => {
    return dasDeviceInfo?.eventData?.[
      eventId
    ]?.infoCollector?.selectedChoices?.find((item) => item.fieldId === fieldId)
      ?.selectedOptionId;
  };

  const getHighlights = (selectedOptionId) => {
    if (
      !publicEvent?.infoCollectorEventData?.fields[1]?.options[0]
        ?.additionalData?.highlights
    )
      return null;

    const groupData = publicEvent?.infoCollectorEventData?.fields?.filter(
      (item) => item?.id === "group"
    )[0];

    const findOptions = groupData?.options?.find(
      (item) => item?.id === selectedOptionId
    );
    // if (findOptions?.additionalData?.walletURL) {
    //   setWalletURL(findOptions?.additionalData?.walletURL);
    // }

    if (findOptions?.additionalData?.highlights) {
      return findOptions.additionalData.highlights;
    }

    return null;
  };

  const getWalletURL = (selectedOptionId) => {
    const groupData = publicEvent?.infoCollectorEventData?.fields?.filter(
      (item) => item?.id === "group"
    )[0];

    const findOptions = groupData?.options?.find(
      (item) => item?.id === selectedOptionId
    );

    if (findOptions?.additionalData?.walletURL) {
      return findOptions?.additionalData?.walletURL;
    }

    return null;
  };

  useEffect(() => {
    if (prevActivatingTicket && !activatingTicketError) {
      dispatch(getDasDeviceInfo(serialNumber));
    }
  }, [prevActivatingTicket, activatingTicketError, dispatch, serialNumber]);

  useEffect(() => {
    if (
      prevGettingDasDeviceInfo &&
      !errorGettingDasDeviceInfo &&
      dasDeviceInfo?.eventData
    ) {
      dispatch(getPublicEventAction(Object.keys(dasDeviceInfo?.eventData)[0]));
    }
  }, [
    gettingDasDeviceInfo,
    prevGettingDasDeviceInfo,
    dasDeviceInfo?.eventData,
    errorGettingDasDeviceInfo,
    dispatch
  ]);

  useEffect(() => {
    setIsMounted(true);

    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    dispatch(getWeatherDataAction());
  }, [dispatch]);

  useEffect(() => {
    if (isMounted) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied, isMounted]);

  useEffect(() => {
    if (!balanceAndPin && serialNumber) {
      dispatch(
        getBalanceAndPin({
          serial: serialNumber,
          query: "?provider=pannovate"
        })
      );
    }
  }, [balanceAndPin, serialNumber, dispatch]);

  useEffect(() => {
    if (serialNumber) {
      dispatch(getDasDeviceInfo(serialNumber));
    }
  }, [serialNumber, dispatch]);

  const [isMemoriesOpen, setIsMemoriesOpen] = useState(false);
  const [isBalanceOpen, setIsBalanceOpen] = useState(false);
  const [isSkiingOpen, setIsSkiingOpen] = useState(false);
  const [isYourStayOpen, setIsYourStayOpen] = useState(false);

  const handleActivateTicket = () => {
    dispatch(activateTicket({ serialNumber, ticketId: "skiiDay" }));
  };

  const handleClose = (closeItem) => {
    const discoverContainer = document.getElementById("discover");
    discoverContainer.scrollIntoView({ behavior: "smooth" });

    switch (closeItem) {
      case "memories":
        setIsMemoriesOpen(false);
        break;
      case "balance":
        setIsBalanceOpen(false);
        break;
      case "skiing":
        setIsSkiingOpen(false);
        break;
      case "yourStay":
        setIsYourStayOpen(false);
        break;
      default:
        break;
    }
  };

  const handleOpen = (openItem) => {
    //scroll to top
    window.scrollTo(0, 0);
    switch (openItem) {
      case "memories":
        setIsMemoriesOpen(true);
        break;
      case "balance":
        setIsBalanceOpen(true);
        break;
      case "skiing":
        setIsSkiingOpen(true);
        break;
      case "yourStay":
        setIsYourStayOpen(true);
        break;
      default:
        break;
    }
  };

  const RACE_ITINERARY = [
    {
      name: "Super G Streif",
      time: "11:30",
      event: "Friday, 24 January"
    },
    {
      name: "DOWNHILL Streif",
      time: "11:30",
      event: "Saturday, 25 January"
    },
    {
      name: "SLALOM 1st run Ganslern*",
      time: "10:15",
      event: "Sunday, 26 January"
    },
    {
      name: "SLALOM 2nd run Ganslern*",
      time: "13:30",
      event: "Sunday, 26 January"
    }
  ];

  const parseHighlights = (highlights) => {
    const parsedHighlights = JSON.parse(highlights);

    return parsedHighlights;
  };

  const renderHighlights = () => {
    const selectedOptionId = getSelectedOptionId("group");

    if (!selectedOptionId) return null;
    const highlights = getHighlights(selectedOptionId);
    if (!highlights) return null;

    return (
      <>
        {parseHighlights(highlights)
          .filter(
            (item) => item.date === new Date().toISOString().split("T")[0]
          )
          .map((item, index) => {
            const formattedDate = new Date(item.date).toLocaleDateString(
              "en-GB",
              {
                weekday: "long",
                day: "numeric",
                month: "long",
                year: "numeric"
              }
            );
            return (
              <ScheduleItem key={index}>
                <div className="schedule-list">
                  <p>{formattedDate}</p>
                  <Separator />

                  {item?.schedule.map((scheduleItem, indexSchedule) => {
                    return (
                      <p className="schedule-item" key={indexSchedule}>
                        <span className="time">
                          {scheduleItem.time ? scheduleItem.time : ""}
                        </span>
                        {scheduleItem.time ? " - " : ""}
                        <span className={!scheduleItem.time ? "time" : ""}>
                          {scheduleItem.title}
                        </span>
                      </p>
                    );
                  })}
                </div>
              </ScheduleItem>
            );
          })}
      </>
    );
  };

  if (isLoading || gettingDasDeviceInfo || gettingPublicEvent)
    return <Spinner />;

  return (
    <MainContainer>
      <NavBar>
        <img src={logo} alt="logo" />
      </NavBar>
      <IntroContainer>
        <div className="image-container">
          {dasDeviceInfo?.personalDetails?.firstName && (
            <h1>
              {"Welcome,"}{" "}
              <span className="new-line">
                {dasDeviceInfo?.personalDetails?.firstName}{" "}
                {dasDeviceInfo?.personalDetails?.lastName}
              </span>
            </h1>
          )}
        </div>

        <div className="text-container">
          <h2>{"Servus!"}</h2>
          <p>
            We are excited to have you join us for the{" "}
            <span className="bold">85th Hahnenkamm Races</span> in Kitzbühel.
            Enjoy a premium experience, priceless surprises, and the thrill of
            Streif!
          </p>
        </div>
      </IntroContainer>
      <ScheduleContainer>
        <h2>{"Today's Highlights"}</h2>
        {renderHighlights()}
      </ScheduleContainer>
      <ScheduleInfoContainer>
        <p>
          {
            "The Mastercard Hospitality Team will be available throughout the event, and will be positioned in the hotel lobby 10 minutes prior to all listed meeting times. Please make sure to adhere to the timings as transportation will depart promptly."
          }
        </p>
      </ScheduleInfoContainer>
      <Separator isLeft={true} />
      <DiscoverContainer id="discover">
        <h2>{"Experience Kitz"}</h2>
        <p>{"Tap to find out what there is to discover"}</p>
        <div className="items-container">
          <DiscoverItem onClick={() => handleOpen("yourStay")}>
            <img src={yourStay} alt="your stay" />
          </DiscoverItem>
          <DiscoverItem onClick={() => handleOpen("skiing")}>
            <img src={skiing} alt="skiing" />
          </DiscoverItem>
          <DiscoverItem onClick={() => handleOpen("balance")}>
            <img src={balance} alt="balance" />
          </DiscoverItem>
          <DiscoverItem onClick={() => handleOpen("memories")}>
            <img src={memories} alt="memories" />
          </DiscoverItem>
        </div>
      </DiscoverContainer>
      <Separator />
      <RacesContainer>
        <h2>{"The Hahnenkamm Races"}</h2>
        <p>{"Peak of Skiing"}</p>
        <p>
          {
            "Attracting 85,000 visitors annually and going at speeds up to 95mph, the annual "
          }
          <span className="bold">{"Hahnenkamm Races "}</span>
          {
            "in Kitzbühel, Austria are known worldwide as fastest and most spectacular skiing event on the men’s FIS Alpine Skiing race calendar."
          }
        </p>
        <p>
          {"As well as being one of the athletic highlights of the "}
          <span className="bold">{"FIS World Cup"}</span>
          {
            ", the Hahnenkamm Race weekend is an exclusive social event, attracting sports afficionados and celebrities alike."
          }
        </p>
        <p>
          {"The "}
          <span className="bold">{"three main races"}</span>
          {
            " - Super G (Friday), Downhill (Saturday), and Slalom (Sunday) - will take place in Kitzbühel, Austria on January 24-26, 2025, and will be attended by fans from all over Europe. "
          }
        </p>
        <h3>{"Race Itinerary"}</h3>
        <div className="itinerary-container">
          {RACE_ITINERARY.map((item, index) => (
            <div className="itinerary-item" key={index}>
              <h3>{item.name}</h3>
              <div className="time-event">
                <p className="time">{item.time}</p>
                <p>
                  {" - "}
                  {item.event}
                </p>
              </div>
            </div>
          ))}
        </div>

        <p>{"*standing area tickets available, no lounge access"}</p>
        <p>
          {"Find more information on the official "}
          <a href="https://hahnenkamm.com/en/">{"Hahnenkamm Races Website."}</a>
        </p>
      </RacesContainer>
      <GetToKnowContainer>
        <img src={getToKnow} alt="get to know kitzbuhel" />
        <div className="text-container">
          <h2>{"Get to know Kitzbühel"}</h2>
          <p>{"The “Gamststadt” awaits"}</p>
        </div>
        <Separator />
        <div className="text-container">
          <p>
            <span className="bold">{"Kitzbühel"}</span>{" "}
            {
              "is a holiday destination in the heart of Tyrol, framed by the Kitzbüheler Horn, the Südberge mountains and the famous Hahnenkamm. If you know Kitzbühel only in wintertime, you still have to discover the majority of the charm of this Alpine region. As"
            }{" "}
            <span className="bold">{"winter sports resort"}</span>{" "}
            {
              "Kitzbühel looks back on a long tradition. However, even before the skiers discovered the s-called “Gamststadt”, guests from near and far came for"
            }{" "}
            <span className="bold">{"summer holidays"}</span>{" "}
            {
              "in Kitzbühel. The good, healthy mountain air, the clear water of the lakes and streams and the gentle, local grass mountains attracted many holidaymakers and hikers already more than a hundred years ago. "
            }
          </p>
          <img src={localAttractions} alt="local attractions" />
          <p>
            <span className="bold">{"Local attractions"}</span>
          </p>
          <p>
            <span className="bold">{"Kitzbühel"}</span> {"unites"}{" "}
            <span className="bold">
              {"urban flair with typical Tyrolean hospitality"}
            </span>
            {
              ", offering a variety of cuisine in addition to first-class shopping opportunities. It’s an enjoyable combination of sports and lifestyle. Kitzbühel’s city centre is"
            }
            <span className="bold">{" quick and easy to navigate"}</span>{" "}
            {
              "and offers the ideal ambience for shopping in the Alps. Window shop international and original "
            }
            <span className="bold">{"Kitzbühel fashion brands"}</span>{" "}
            {
              "in stores like Sportalm, Frauenschuh, Franz Prader or Helmut Eder. A tour of discovery at the craft workshops of the Kitzbühel Master Guild is also worthwhile."
            }
          </p>
        </div>
      </GetToKnowContainer>
      <LanguageGuideContainer>
        <h2>{"Austrian for Beginners"}</h2>
        <p>{"Blend right in with these Austrian phrases:"}</p>
        <p className="bold">{"Servus"}</p>
        <p>{"A common and versatile greeting, similar to “hello” or “hi”."}</p>
        <p className="bold">{"Grüß Gott"}</p>
        <p>{"A more formal greeting, especially in rural areas."}</p>
        <p className="bold">{"Wiederschaun"}</p>
        <p>{"A formal “goodbye” when you are leaving."}</p>
        <p className="bold">{"Pfiat di"}</p>
        <p>{"A less formal “goodbye”."}</p>
        <p className="bold">{"Bist du deppert!"}</p>
        <p>
          {
            "Roughly: “Are you out of your mind?”, often used to express surprise or disbelief."
          }
        </p>
        <p>
          {"Find more information on the official "}
          <a href="https://www.hahnenkamm.com/en/races/hahnenkamm-races-2025">
            {"Hahnenkamm Races Website."}
          </a>
        </p>
      </LanguageGuideContainer>
      <FooterContainer>
        <h3>{"Powered by"}</h3>
        <img src={poweredBy} alt="powered by" />
        <h3>{"Payment services by"}</h3>
        <img src={paymentServices} alt="payment services" />
        <p>
          <a href="https://www.mastercard.com/global/en.html">
            {"Privacy Policy"}
          </a>
        </p>
        <p>
          <a href="https://www.mastercard.com/global/en.html">
            {"Terms & Conditions"}
          </a>
        </p>
        {serialNumber && (
          <div className="copyToClipboard">
            <p>ID: {serialNumber}</p>
            <img
              onClick={() => {
                navigator.clipboard.writeText(serialNumber);
                setCopied(true);
              }}
              src={copyIconWhite}
              alt="copy to clipboard"
            />
          </div>
        )}
        <div className="version">
          <p>v.{packageJson.version}</p>
        </div>
        {copied && (
          <p>
            <b>Copied to clipboard</b>
          </p>
        )}
      </FooterContainer>
      {isMemoriesOpen && (
        <Memories
          serialNumber={serialNumber}
          onClose={() => handleClose("memories")}
        />
      )}
      {isBalanceOpen && (
        <Balance
          onClose={() => handleClose("balance")}
          remainingBalance={balanceAndPin?.balanceDetails?.availableBalance}
          activationCode={balanceAndPin?.activationCode}
        />
      )}
      {isSkiingOpen && (
        <Skiing
          onClose={() => handleClose("skiing")}
          onActivateTicket={() => handleActivateTicket()}
          activatingTicket={activatingTicket}
          activatingTicketError={activatingTicketError}
          ticketStatus={dasDeviceInfo?.promoReadyTickets?.[0]?.ticketStatus}
        />
      )}

      {isYourStayOpen && (
        <YourStay
          onClose={() => handleClose("yourStay")}
          hotelInfo={
            infoCollectorEventData?.fields?.length > 0 &&
            infoCollectorEventData?.fields?.filter(
              (item) => item?.id === "hotel"
            )[0]
          }
          hotelOptions={
            dasDeviceInfo?.eventData?.[eventId]?.infoCollector?.selectedChoices
              ?.length > 0 &&
            dasDeviceInfo?.eventData?.[
              eventId
            ]?.infoCollector?.selectedChoices.filter(
              (item) => item?.fieldId === "hotel"
            )[0]
          }
          walletURL={getWalletURL(getSelectedOptionId("group"))}
        />
      )}
      {weatherData && (
        <div style={{ position: "fixed", top: "90%", right: "10px" }}>
          <WeatherWidget
            weatherData={weatherData}
            loading={loadingWeather}
            error={errorWeather}
          />
        </div>
      )}
    </MainContainer>
  );
};

export default Kitzbuhel25;
