import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { getSportsDataAction } from "../../features/sports/sportsSlice";
import { getBalanceAndPin } from "../../features/balance-and-pin/balanceAndPinSlice";
import InfoAlert from "./InfoAlert";
import Spinner from "../../components/Spinner";
import {
  MainContainer,
  Intro,
  Balance,
  Schedule,
  LiveFeed,
  AoMap,
  Wearable,
  Store,
  MastercardCam,
  Priceless,
  Donate,
  Footer,
  Button,
  Game
} from "./styles";

import donateImg from "./imgs/donate.png";
import infoIcon from "./imgs/info.svg";
import logos from "./imgs/logos.png";
import personIcon from "./imgs/person.svg";
import wearableImg from "./imgs/wearable.webp";
import digiseqLogo from "./imgs/digiseq-logo.svg";
import copyIconWhite from "../../assets/copy-icon-white.svg";

import packageJson from "../../../package.json";

const Ao25 = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");
  const rate = searchParams.get("rate");
  const today = searchParams.get("date");

  const dispatch = useDispatch();

  const [isInfoAlertOpen, setIsInfoAlertOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  const { sportsData, gettingSportsData, errorGettingSportsData } = useSelector(
    (state) => state.sports
  );

  const { balanceAndPin, isLoading } = useSelector(
    (state) => state.balanceAndPin
  );

  console.log({ errorGettingSportsData });

  const formatName = (fullName) => {
    const nameParts = fullName.split(" ");
    const formattedName = nameParts
      .map((part, index) =>
        index < nameParts.length - 1 ? `${part[0]}.` : part
      )
      .join(" ");
    return formattedName;
  };

  useEffect(() => {
    setIsMounted(true);

    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    if (isMounted) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied, isMounted]);

  useEffect(() => {
    if (!balanceAndPin && serialNumber) {
      dispatch(
        getBalanceAndPin({
          serial: serialNumber,
          query: "?provider=much_better"
        })
      );
    }
  }, [balanceAndPin, serialNumber, dispatch]);

  useEffect(() => {
    dispatch(getSportsDataAction({ sportId: 13, leagueId: 13657 }));
  }, [dispatch]);

  const SCHEDULES = [
    {
      date: "22012025",
      description: "Forums 22nd",
      schedule: [
        { time: "10:00 am", event: "Arrivals and registration" },
        { time: "10:30 am", event: "Customer Forum commences" },
        { time: "3:30 pm", event: "Autonomous store tour" },
        { time: "5:00 pm", event: "Priceless Surprise" },
        { time: "5:30 pm", event: "Pre-match hospitality and networking" },
        { time: "7:00 pm", event: "Tennis at Rod Laver Arena" },
        { time: "10:00 pm", event: "Venue Close" }
      ]
    },
    {
      date: "23012025",
      description: "Forums 23rd",
      schedule: [
        { time: "10:00 am", event: "Arrivals and registration" },
        { time: "10:30 am", event: "Customer Forum commences" },
        { time: "3:30 pm", event: "Autonomous store tour" },
        { time: "4:30 pm", event: "Priceless Surprise" },
        { time: "5:00 pm", event: "Pre-match hospitality and networking" },
        { time: "7:00 pm", event: "Tennis at Rod Laver Arena" },
        { time: "10:00 pm", event: "Venue Close" }
      ]
    },
    {
      date: "24012025",
      description: "Forums 24th",
      schedule: [
        { time: "10:00 am", event: "Arrivals and registration" },
        { time: "10:30 am", event: "Customer Forum commences" },
        { time: "3:30 pm", event: "Autonomous store tour" },
        { time: "4:30 pm", event: "Pre-match hospitality and networking" },
        { time: "6:30 pm", event: "Priceless Surprise" },
        { time: "7:00 pm", event: "Tennis at Rod Laver Arena" },
        { time: "10:00 pm", event: "Venue Close" }
      ]
    },
    {
      date: "25012025",
      description: "Finals 25th",
      schedule: [
        { time: "11:30 am", event: "Arrivals and registration" },
        { time: "12:00 pm", event: "Customer Forum commences" },
        { time: "5:00 pm", event: "Pre-match hospitality and networking" },
        { time: "6:20 pm", event: "Priceless Surprise" },
        { time: "7:00 pm", event: "Tennis at Rod Laver Arena" },
        { time: "10:00 pm", event: "Venue Close" }
      ]
    },
    {
      date: "26012025",
      description: "Finals 26th",
      schedule: [
        { time: "5:00 pm", event: "Arrivals" },
        { time: "5:30 pm", event: "Welcome address" },
        { time: "5:35 pm", event: "Pre-match hospitality and networking" },
        { time: "7:00 pm", event: "Tennis at Rod Laver Arena" },
        { time: "9:00 pm", event: "Beverages Close" },
        { time: "10:00 pm", event: "Venue Close" }
      ]
    }
  ];

  const filteredSchedule = SCHEDULES.find(
    (schedule) => schedule.date === today
  );

  if (isLoading || gettingSportsData) return <Spinner />;

  return (
    <MainContainer>
      <Intro>
        <img src={logos} alt="logos" />
        <h1>Payments, People and Priceless Possibilities</h1>
        <p>
          In an increasingly complex landscape, we are all united in creating
          not only seamless transactions but also meaningful human connections.
          Join us for Payments, People and Priceless Possibilities, where we’ll
          explore how our combined efforts unlock priceless possibilities for
          Australians every day. Our discussions will highlight how secure,
          convenient payments facilitate life’s most cherished moments, showing
          the true impact of the payments ecosystem beyond simply moving money.
        </p>
      </Intro>
      {balanceAndPin?.balanceDetails?.availableBalance && (
        <Balance>
          <h2>My Balance</h2>
          {balanceAndPin?.cardDetails?.pan &&
            balanceAndPin?.cardDetails?.expiryDate && (
              <img
                src={infoIcon}
                alt="info"
                onClick={() => setIsInfoAlertOpen(true)}
              />
            )}
          <p>
            $
            {rate
              ? (
                  balanceAndPin?.balanceDetails?.availableBalance * rate
                ).toFixed(2)
              : (balanceAndPin?.balanceDetails?.availableBalance).toFixed(2)}
          </p>
        </Balance>
      )}
      <Schedule>
        <h2>Today’s Schedule (AET)</h2>
        <div className="schedule-container">
          {filteredSchedule ? (
            <>
              <h2 className="schedule-title">{filteredSchedule.description}</h2>
              {filteredSchedule.schedule.map((item, index) => (
                <p key={index}>
                  <span className="time">{item.time}</span>
                  <span> - </span>
                  <span className="event">{item.event}</span>
                </p>
              ))}
            </>
          ) : (
            <p>No schedule available for today.</p>
          )}
        </div>
      </Schedule>
      {sportsData.length > 0 && (
        <LiveFeed>
          <h2>Live Feed</h2>
          <Game>
            {sportsData.map((item) => (
              <div key={item.id} className="round-container">
                <p>
                  {item.round} {item.round && item.stadium ? "-" : ""}
                  {item.stadium}
                </p>
                <div className="game-container">
                  <div className="player">
                    <img src={personIcon} alt="person" />
                    <p>{formatName(item.home?.name)}</p>
                  </div>
                  <div className="score">
                    {Object.keys(item.scores).map((score, index) => {
                      return (
                        <div className="score-container" key={index}>
                          <span
                            className={
                              item.scores[score].home > item.scores[score].away
                                ? "current"
                                : ""
                            }
                          >
                            {item.scores[score].home}
                          </span>
                          <span
                            className={
                              item.scores[score].away > item.scores[score].home
                                ? "current"
                                : ""
                            }
                          >
                            {item.scores[score].away}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                  <div className="player">
                    <img src={personIcon} alt="person" />
                    <p>{formatName(item.away?.name)}</p>
                  </div>
                </div>
                <p>Live</p>
              </div>
            ))}
          </Game>
        </LiveFeed>
      )}
      <AoMap>
        <h2>Australian Open Map</h2>
        <Button
          onClick={() =>
            window.open(
              "https://s3.eu-west-2.amazonaws.com/www.digiseq.co.uk/mastercard/AO25+Mastercard+Map+V2.pdf",
              "_blank"
            )
          }
        >
          Open Map
        </Button>
      </AoMap>
      <Wearable>
        <h2>Mastercard Wristband</h2>
        <div className="wearable-img">
          <img src={wearableImg} alt="wearable" />
        </div>
        <p>
          This wearable wristband combines style with innovative technology,
          featuring both light and haptic feedback capabilities. With two keys
          functionalities, the band enables a unique experience: ‘Broadcast’
          mode synchronizes lights and haptics across all bands, creating a
          mesmerizing display, while ‘Nearfield’ mode allows customers to tap
          and pay or access event information with ease. Our customers will be
          among the first to experience this groundbreaking technology,
          exclusively at the Mastercard customer forum.
        </p>
      </Wearable>
      <Store>
        <h2>Autonomous Store</h2>
        <p>
          In today’s economic climate, shoppers are looking for more value,
          convenience, seamless, and secure experiences across online and
          offline touchpoints. The Frictionless Bar and Quickserve by Mastercard
          at AO25 is a great example of hose we’re meeting these expectations.
          Using Aifi’s autonomous retail platform and Mastercard’s secure
          payment technology, we’ve created an autonomous shopping experience
          that’s fast, easy, and designed to enhance the fan experience.
        </p>
      </Store>
      <MastercardCam>
        <h2>Mastercard Cam</h2>
        <p>
          Capture your moment at the Australian Open with the Mastercard Cam for
          a chance to win an exclusive Mastercard prize and make your AO
          experience even more unforgettable.
        </p>
        <Button
          onClick={() =>
            window.open("https://www.priceless.com/ao25-fancam", "_blank")
          }
        >
          See more
        </Button>
      </MastercardCam>
      <Priceless>
        <h2>Priceless.com</h2>
        <p>
          Explore priceless.com to discover exclusive Australian Open
          experiences and other unique rewards tailored just for you.
        </p>
        <Button
          onClick={() => window.open("https://www.priceless.com/", "_blank")}
        >
          See more
        </Button>
      </Priceless>
      <Donate
        bgImg={donateImg}
        onClick={() =>
          window.open("https://give.mastercard.com/p/ppc-dedication", "_blank")
        }
      >
        <Button>Donate now</Button>
      </Donate>
      <Footer>
        <h2>Powered by</h2>
        <div className="logo">
          <img src={digiseqLogo} alt="powered by digiseq" />
        </div>
        {serialNumber && (
          <div className="copyToClipboard">
            <p>ID: {serialNumber}</p>
            <img
              onClick={() => {
                navigator.clipboard.writeText(serialNumber);
                setCopied(true);
              }}
              src={copyIconWhite}
              alt="copy to clipboard"
            />
          </div>
        )}
        {copied && (
          <p>
            <b>Copied to clipboard</b>
          </p>
        )}
        <div className="version">
          <p>v.{packageJson.version}</p>
        </div>
      </Footer>
      {isInfoAlertOpen && (
        <InfoAlert
          panNumber={balanceAndPin?.cardDetails?.pan}
          expiryDate={balanceAndPin?.cardDetails?.expiryDate}
          onClose={() => setIsInfoAlertOpen(false)}
        />
      )}
    </MainContainer>
  );
};

export default Ao25;
