import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { composeErrorMessage } from "../../utils/utils";
import { publicEventsManagerService } from "./publicEventsManagerService";

const initialState = {
  publicEvent: null,
  loading: false,
  error: null
};

export const getPublicEventAction = createAsyncThunk(
  "publicEventsManager/getPublicEvent",
  async (id, thunkAPI) => {
    try {
      const response = await publicEventsManagerService.getPublicEvent(id);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

const publicEventsManagerSlice = createSlice({
  name: "publicEventsManager",
  initialState,
  reducers: {
    resetPublicEvent: (state) => {
      state.publicEvent = null;
      state.gettingPublicEvent = false;
      state.errorGettingPublicEvent = "";
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPublicEventAction.pending, (state) => {
        state.gettingPublicEvent = true;
        state.errorGettingPublicEvent = "";
      })
      .addCase(getPublicEventAction.fulfilled, (state, action) => {
        state.gettingPublicEvent = false;
        state.publicEvent = action.payload;
      })
      .addCase(getPublicEventAction.rejected, (state, action) => {
        state.gettingPublicEvent = false;
        state.errorGettingPublicEvent = action.payload;
      });
  }
});

export const { resetPublicEvent } = publicEventsManagerSlice.actions;
export default publicEventsManagerSlice.reducer;
