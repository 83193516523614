import FaqItem from "./FaqItem";

import { InfoAlert } from "./styles";
import closeIcon from "./imgs/close-icon.svg";
import headerImg from "./imgs/your-stay-banner.webp";

const YourStay = ({ onClose, hotelInfo, hotelOptions, walletURL }) => {
  const parseHotelAddress = (address) => {
    return address.split("\n").map((line, i) => <p key={i}>{line}</p>);
  };

  const FAQS = [
    {
      category: "Transportation",
      questions: [
        {
          question: "Do I need to book my own airline reservations?",
          answer:
            "Yes. Mastercard will not be booking any flight reservations. Please provide flight information for transportation arrangements."
        },
        {
          question: "What transportation is included in the program?",
          answer:
            "Transportation to/from Munich (MUC) and Innsbruck (INN) airport or Kitzbühel train station, as well as to/from all organized activities like Dinners and Race Day."
        },
        {
          question:
            "What happens if I am late and miss the designated transfer?",
          answer:
            "If you miss the designated group transfer, inform a team member immediately. You will need to arrange your own transportation."
        },
        {
          question: "Where will I be picked up?",
          answer:
            "Munich or Innsbruck airport arrivals: driver in arrival hall after passport control.\nKitzbühel train station: driver at platform exit."
        },
        {
          question: "Can I travel with my own car?",
          answer:
            "Race days have road closures and longer waiting times. Public transport or shuttle service recommended.\nLimited hotel parking available."
        },
        {
          question: "How do I get around on site?",
          answer:
            "Public transportation is limited during event days. Arrange your own taxi via the hotel desk at your own expense."
        }
      ]
    },
    {
      category: "Accommodation",
      questions: [
        {
          question: "What costs are included in my accommodation?",
          answer: "Room, applicable taxes (VAT), breakfast, Wi-Fi."
        },
        {
          question:
            "Will I be responsible for covering the hotel costs during my stay in Kitzbühel?",
          answer:
            "You cover all additional costs (minibar, phone, bar bills, in-room services, laundry, etc.).\nHotel requires a Mastercard credit card for incidentals."
        },
        {
          question:
            "If I want to extend my stay an additional night, what do I do?",
          answer:
            "Hotel availability is not guaranteed. Notify the team as soon as possible for assistance."
        }
      ]
    },
    {
      category: "General Information",
      questions: [
        {
          question: "What is the currency in Kitzbühel?",
          answer: "Euro (€), credit and debit cards widely accepted."
        },
        {
          question:
            "What is the phone number of the emergency service in Kitzbühel?",
          answer: "Police: 110\nFire/Medical: 112"
        },
        {
          question: "What is the weather like in Kitzbühel in January?",
          answer: "Average high: 1°C / 33°F\nAverage low: -6°C / 21°F"
        },
        {
          question: "What is the dress code for the program?",
          answer:
            "All activities: Winter casual. Warm, water-resistant clothing recommended (jacket, hat, gloves, winter boots).\nBusiness sessions: Smart casual."
        },
        {
          question: "Where can I find ski gear rentals in Kitzbühel?",
          answer:
            "Sport ETZ (Kempinski Hotel): Open daily 8:30-13:00, 15:00-18:00\nIntersport Hahnenkammbahn (Kitzbühel Downtown): Open Monday-Saturday 8:30-18:00"
        },
        {
          question:
            "If I have general questions regarding the event, who do I contact?",
          answer: "For general questions, contact: kitzbuehel@octagon.com"
        },
        {
          question: "Who do I contact on-site with event questions?",
          answer: "Mastercard Hospitality Team."
        },
        {
          question: "Who do I contact to change my registration?",
          answer:
            "Use email to log back into the registration site. Save confirmation email for future edits."
        },
        {
          question: "What is YourPass?",
          answer:
            "YourPass uses mobile wallet technology for digital passports.\nProvides event updates via push notifications."
        }
      ]
    }
  ];

  const hotelDetails = hotelInfo?.options?.find(
    (item) => item?.id === hotelOptions?.selectedOptionId
  );

  return (
    <InfoAlert>
      <div className="inner-container">
        <div className="img-container">
          <img src={headerImg} alt="header" />
          <h2>{"Your Stay"}</h2>
        </div>
        <div className="close-button" onClick={onClose}>
          <img src={closeIcon} alt="close" />
        </div>
        <div className="scroll-view">
          <div className="section">
            <p className="bold">{"Accommodation"}</p>
          </div>

          {hotelDetails?.item && (
            <div className="section bg-black hotel-section">
              <img src={hotelDetails?.additionalData?.image} alt="hotel" />
              <div className="hotel-info">
                <p className="bold">{hotelDetails?.item}</p>
                {hotelDetails?.additionalData?.address && (
                  <div>
                    {parseHotelAddress(hotelDetails.additionalData.address)}
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="section">
            <p className="bold">{"FAQs"}</p>
            <div className="faq-container">
              {FAQS.map((faq, index) => (
                <FaqItem
                  key={index}
                  {...faq}
                  isLast={index === FAQS.length - 1}
                />
              ))}
            </div>
          </div>
          <div className="section">
            <p className="bold">{"Stay updated"}</p>
            <p>
              {
                "Add your event pass to your mobile wallet and receive real-time event information via push notifications."
              }
            </p>
            <div
              className="button button-fixed"
              onClick={() => {
                window.open(walletURL, "_blank");
              }}
            >
              <p>{"Tap here to add"}</p>
            </div>
          </div>
        </div>
      </div>
    </InfoAlert>
  );
};

export default YourStay;
