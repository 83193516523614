import React, { useState, useEffect } from "react";
import i18n from "i18next";
import { LanguageSelector } from "./styles";

import chevronDownWhite from "./img/chevron-down-white.svg";
import chevronUpWhite from "./img/chevron-up-white.svg";

const LanguageSelect = () => {
  const [lang, setLang] = useState(i18n.language || "de");
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    i18n.changeLanguage("en");
    setLang("en");
  }, []);

  useEffect(() => {
    const handleLanguageChanged = (lng) => {
      setLang(lng);
      setIsOpen(false);
    };

    i18n.on("languageChanged", handleLanguageChanged);

    return () => {
      i18n.off("languageChanged", handleLanguageChanged);
    };
  }, []);

  const handleLanguageChange = (language) => {
    setLang(language);
    i18n.changeLanguage(language);
    setIsOpen(false);
  };

  return (
    <LanguageSelector isOpen={isOpen}>
      <div className={`language-container ${lang === "de" ? "reverse" : ""}`}>
        <div
          className={lang === "fr" && !isOpen ? "hidden" : ""}
          onClick={() => handleLanguageChange("en")}
        >
          <p>{"EN"}</p>
        </div>
        <div
          className={lang === "en" && !isOpen ? "hidden" : ""}
          onClick={() => handleLanguageChange("fr")}
        >
          <p>{"FR"}</p>
        </div>
      </div>
      <div className="chevron-container">
        {isOpen ? (
          <img
            src={chevronUpWhite}
            alt="chevron"
            onClick={() => setIsOpen(false)}
          />
        ) : (
          <img
            src={chevronDownWhite}
            alt="chevron"
            onClick={() => setIsOpen(true)}
          />
        )}
      </div>
    </LanguageSelector>
  );
};

export default LanguageSelect;
