import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { composeErrorMessage } from "../../utils/utils";

import infoCollectorService from "./infoCollectorService";

const initialState = {
  fetchingDevices: false,
  fetchingDevicesError: "",
  devices: [],
};

export const getInfoCollectorDevices = createAsyncThunk(
  "infoCollector/getInfoCollectorDevices",
  async (eventId, thunkAPI) => {
    try {
      const response = await infoCollectorService.getDevices(eventId);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const informationTrackerSlice = createSlice({
  name: "informationTracker",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInfoCollectorDevices.pending, (state) => {
        state.devices = [];
        state.fetchingDevices = true;
        state.fetchingDevicesError = "";
      })
      .addCase(getInfoCollectorDevices.fulfilled, (state, action) => {
        state.fetchingDevices = false;
        state.devices = action.payload;
      })
      .addCase(getInfoCollectorDevices.rejected, (state, action) => {
        state.fetchingDevices = false;
        state.fetchingDevicesError = action.payload;
      });
  },
});

export const { reset } = informationTrackerSlice.actions;
export default informationTrackerSlice.reducer;
