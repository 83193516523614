import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import kitzbuhelService from "./kitzbuhelService";

const initialState = {
  weatherData: null,
  loadingWeather: false,
  errorWeather: null
};

export const getWeatherDataAction = createAsyncThunk(
  "kitzbuhel/getWeatherData",
  async (_, thunkAPI) => {
    return await kitzbuhelService.getWeatherData();
  }
);

const kitzbuhelSlice = createSlice({
  name: "kitzbuhel",
  initialState,
  reducers: {
    resetWeatherData: (state) => {
      state.weatherData = null;
      state.loadingWeather = false;
      state.errorWeather = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getWeatherDataAction.pending, (state) => {
      state.loadingWeather = true;
    });
    builder.addCase(getWeatherDataAction.fulfilled, (state, action) => {
      state.weatherData = action.payload;
      state.loadingWeather = false;
    });
    builder.addCase(getWeatherDataAction.rejected, (state, action) => {
      state.errorWeather = action.error.message;
      state.loadingWeather = false;
    });
  }
});

export const { resetWeatherData } = kitzbuhelSlice.actions;

export default kitzbuhelSlice.reducer;
