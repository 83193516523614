import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import sportsService from "./sportsService";

const initialState = {
  sportsData: [],
  gettingSportsData: false,
  errorGettingSportsData: null
};

export const getSportsDataAction = createAsyncThunk(
  "sports/getSportsData",
  async ({ sportId, leagueId }) => {
    const response = await sportsService.getSportsData({ sportId, leagueId });
    return response.results;
  }
);

const sportsSlice = createSlice({
  name: "sports",
  initialState,
  reducers: {
    resetSportsData: (state) => {
      state.sportsData = [];
      state.gettingSportsData = false;
      state.errorGettingSportsData = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getSportsDataAction.pending, (state) => {
      state.gettingSportsData = true;
      state.errorGettingSportsData = null;
    });
    builder.addCase(getSportsDataAction.fulfilled, (state, action) => {
      state.sportsData = action.payload;
      state.gettingSportsData = false;
    });
    builder.addCase(getSportsDataAction.rejected, (state, action) => {
      state.gettingSportsData = false;
      state.errorGettingSportsData = action.error;
    });
  }
});

export const { resetSportsData } = sportsSlice.actions;
export default sportsSlice.reducer;
