import { InfoAlert } from "./styles";
import closeIcon from "./imgs/close-icon.svg";
import headerImg from "./imgs/skiing-banner.webp";
import skiIcon from "./imgs/ski-icon.svg";

const Skiing = ({
  onClose,
  onActivateTicket,
  activatingTicket,
  activatingTicketError,
  ticketStatus
}) => {
  const ticketActivated = ticketStatus && ticketStatus === "ACTIVE";

  return (
    <InfoAlert>
      <div className="inner-container">
        <div className="img-container">
          <img src={headerImg} alt="header" />
          <h2>{"Skiing"}</h2>
        </div>
        <div className="close-button" onClick={onClose}>
          <img src={closeIcon} alt="close" />
        </div>
        <div className="scroll-view">
          <div className="section">
            <p>
              {
                "Kitzbühel is not only about watching races, but also about experiencing the joys of skiing yourself. Use this fob to unlock your own white powder adventures!"
              }
            </p>
          </div>
          <div className="section bg-black">
            <p className="bold">{"Ski pass"}</p>
            <p>
              {
                "Activate your KitzSki Day Ticket and discover the slopes of Kirchberg and Kitzbühel! Don’t forget to visit this page tomorrow to activate another Day ticket."
              }
            </p>
            {!ticketActivated ? (
              <div className="group btn-group">
                <div
                  className={
                    activatingTicket ||
                    activatingTicketError ===
                      "No remaining redemptions for this ticket"
                      ? "button button-disabled"
                      : "button"
                  }
                  onClick={onActivateTicket}
                >
                  <p className="bold">{"Activate"}</p>
                </div>
                <div>
                  <img
                    className={activatingTicket ? "icon-processing" : "icon"}
                    src={skiIcon}
                    alt="ski"
                  />
                </div>
              </div>
            ) : (
              <div className="group btn-group group-reversed">
                <div className={"button button-secondary "}>
                  <p className="bold">{"Activated"}</p>
                </div>
                <div>
                  <img className={"icon"} src={skiIcon} alt="ski" />
                </div>
              </div>
            )}
            {activatingTicketError && (
              <p className="error-message">
                {activatingTicketError ===
                "No remaining redemptions for this ticket"
                  ? activatingTicketError
                  : "Ups. Something went wrong. Please try again or contact support."}
              </p>
            )}
          </div>
          <div className="section">
            <p className="bold">{"Gear rental"}</p>
            <p>
              {
                "If needed you can rent boots, ski/board, sticks and helmets near your accommodation."
              }
            </p>
          </div>
          <div className="section">
            <div className="group">
              <p className="bold">{"Sport ETZ"}</p>
              <p>{"Kempinski Hotel"}</p>
            </div>
            <div className="group">
              <p>{"daily 8:30-13:00"}</p>
              <p>{"and 15:00-18:00"}</p>
            </div>
          </div>
          <div className="section">
            <div className="group">
              <p className="bold">{"Kitzsport"}</p>
              <p className="bold"> {"Hahnenkammbahn"}</p>
            </div>
            <div className="group">
              <p>{"Monday to Saturday"}</p>
              <p>{"8:30-18:00"}</p>
            </div>
            <p>{"Kitzbühel Downtown"}</p>
          </div>
          <div className="bg-skiing-footer">
            <p>
              {
                "We strongly recommend all skiers and boarders to download the KitzSki App"
              }
            </p>
            <div
              className="button button-fixed"
              onClick={() => {
                window.open(
                  `https://www.kitzski.at/en/service-info/kitzski-app.html`,
                  "_blank"
                );
              }}
            >
              <p>{"Tap here"}</p>
            </div>
          </div>
        </div>
      </div>
    </InfoAlert>
  );
};

export default Skiing;
