import { useState } from "react";
import {
  IconContainer,
  WeatherInfoContainer,
  WeatherWidgetContainer
} from "./weatherWidget.styles";

const WeatherWidget = ({ weatherData, loading, error }) => {
  const [hidden, setHidden] = useState(false);

  const getWeatherIcon = (weatherCode) => {
    const weatherIcons = {
      0: "☀️", // Clear
      1: "🌤️", // Mainly clear
      2: "⛅", // Partly cloudy
      3: "☁️", // Overcast
      45: "🌫️", // Fog
      48: "🌫️", // Freezing fog
      51: "🌧️", // Drizzle
      53: "🌧️", // Drizzle
      55: "🌧️", // Drizzle
      56: "🌧️", // Drizzle
      57: "🌧️", // Drizzle
      61: "🌧️", // Rain
      63: "🌧️", // Rain
      65: "🌧️", // Rain
      71: "❄️", // Snowfall
      73: "❄️", // Snowfall
      75: "❄️", // Snowfall,
      77: "❄️", // Snowfall,
      80: "🌧️", // Rain
      81: "🌧️", // Rain
      82: "🌧️", // Rain
      95: "⛈️", // Thunderstorm
      96: "⛈️", // Thunderstorm
      99: "⛈️" // Thunderstorm
    };
    return weatherIcons[weatherCode] || "❓";
  };

  if (loading) {
    return <p>Loading weather...</p>;
  }

  if (error) {
    console.log("Weather error: ", error);
    return null;
  }

  const {
    temperature_2m_min: minTemp,
    temperature_2m_max: maxTemp,
    weathercode
  } = weatherData?.daily;

  const todayMin = minTemp[0];
  const todayMax = maxTemp[0];
  const todayWeatherCode = weathercode[0];
  const weatherIcon = getWeatherIcon(todayWeatherCode);

  if (!weatherData?.daily) {
    return null;
  }

  return (
    <WeatherWidgetContainer hidden={hidden} onClick={() => setHidden(!hidden)}>
      <IconContainer>{weatherIcon}</IconContainer>
      <WeatherInfoContainer>
        <p>
          {Math.round(todayMin)}° / {Math.round(todayMax)}°
        </p>
      </WeatherInfoContainer>
    </WeatherWidgetContainer>
  );
};

export default WeatherWidget;
