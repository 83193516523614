import { InfoAlert } from "./styles";
import closeIcon from "./imgs/close-icon.svg";
import headerImg from "./imgs/memories-banner.webp";
import personIcon from "./imgs/person-icon.svg";
import cameraIcon from "./imgs/camera-icon.svg";

const Memories = ({ serialNumber, onClose }) => {
  return (
    <InfoAlert>
      <div className="inner-container">
        <div className="img-container">
          <img src={headerImg} alt="header" />
          <h2>{"Memories"}</h2>
        </div>
        <div className="close-button" onClick={onClose}>
          <img src={closeIcon} alt="close" />
        </div>
        <div className="scroll-view">
          <div className="section">
            <p>
              {
                "To remember your time and all the priceless moments in Kitz - download your photos here."
              }
            </p>
          </div>
          <div className="section bg-black">
            <p className="bold">{"My Memories"}</p>
            <p>{"Click here to see your personalised collection."}</p>
            <div className="group btn-group group-reversed">
              <div
                className="button"
                onClick={() => {
                  window.open(
                    `https://memento.photo/j/vtfOk_XE?eat=${serialNumber}`,
                    "_blank"
                  );
                }}
              >
                <p>{"View Memories"}</p>
              </div>
              <div>
                <img src={personIcon} alt="person" />
              </div>
            </div>
          </div>
          {/* <div className="section">
            <p className="bold">{"My Memories"}</p>
            <p>{"Find more photos here."}</p>
            <div className="group btn-group group-reversed">
              <div className="button">
                <p>{"View Kitzbühel Gallery"}</p>
              </div>
              <div>
                <img src={cameraIcon} alt="camera" />
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </InfoAlert>
  );
};

export default Memories;
