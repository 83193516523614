import styled from "styled-components";

export const WeatherWidgetContainer = styled.div`
  cursor: pointer;
  position: relative;
  top: 0px;
  transition: right 0.3s ease-in-out;
  right: ${({ hidden }) => (hidden ? "-105px" : "0px")};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d9d9d9;
  border-radius: 90px;
  width: auto;
  height: 58px;
  padding-right: 5px;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  margin-right: 5px;
  background-color: #1d4c71;
  border-radius: 90px;
  width: 58px;
  height: 58px;
`;

export const WeatherInfoContainer = styled.div`
  margin: 0;
  p {
    font-family: "Mark For MC Narrow W00 Book";
    font-size: 16.85px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    color: #000000;
  }
`;
