export const formatIsoDate = (isoDate, option) => {
  const date = new Date(isoDate).toLocaleDateString("en-gb");
  const time = new Date(isoDate).toLocaleTimeString("en-gb", {
    timeStyle: "short",
    hour12: false,
    timeZone: "Europe/London",
  });

  switch (option) {
    case "date":
      return date;
    case "time":
      return time;

    default:
      return `${date} ${time}`;
  }
};

export const secondsRemainingFromDate = (targetDate) => {
  const now = new Date();
  const target = new Date(targetDate);

  if (isNaN(target)) {
    console.error("Invalid target date:", targetDate);
    return 0;
  }

  const nowMinutesAndSeconds = now.getUTCMinutes() * 60 + now.getUTCSeconds();
  const targetMinutesAndSeconds = target.getUTCMinutes() * 60 + target.getUTCSeconds();

  const difference = targetMinutesAndSeconds - nowMinutesAndSeconds;

  if (difference < 0) {
    return 0;
  }

  return difference;
};

export const withoutMilliseconds = (isoStr) =>
  isoStr.includes(".") ? isoStr.split(".")[0] : isoStr;
