import { useState } from "react";
import chevronDown from "./imgs/chevron-down.svg";
import chevronUp from "./imgs/chevron-up.svg";

const FaqItem = ({ category, questions, isLast }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="faq-container">
      <div className={`faq-item ${isLast ? "faq-no-border" : ""}`}>
        <p>
          <span className="bold">{`${category}: `}</span>
        </p>
        <img
          src={isOpen ? chevronUp : chevronDown}
          alt="chevron-down"
          onClick={() => setIsOpen(!isOpen)}
        />
      </div>
      {isOpen &&
        questions.map(({ question, answer }) => (
          <div>
            <p className="bold">{question}</p>
            <p>{answer}</p>
          </div>
        ))}
    </div>
  );
};

export default FaqItem;
