import axios from "axios";
import { ENV } from "../../env";

export const SECURITY_API_URL = `https://www.${
  ENV !== "production" ? (ENV === "development" ? "dev" : ENV) + "-" : ""
}manage-mii.uk/api/security`;

const get2FaAuthCode = async ({ serialNumber, accessToken }) => {

  const response = await axios.get(
    SECURITY_API_URL + `/2fa/${serialNumber}/code`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return response.data;
};

const twoFactorAuthService = {
  get2FaAuthCode,
};

export default twoFactorAuthService;
