import styled from "styled-components";

export const NextStepsContainer = styled.div`
  margin-top: -1px;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px;
  background-color: white;
`;

export const StepContainer = styled.div`
  padding: 32px 16px;
  h2 {
    margin-top: 16px;
    font-family: "Inter";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
  }
  p {
    margin-top: 16px;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
    columns: #626262;
  }
  .pay-anywhere {
    margin: 24px auto;
    width: 100%;
    object-fit: contain;
  }
`;

export const CurvelogoContainer = styled.div`
  margin-bottom: 16px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  .separator {
    height: 25px;
    object-fit: cover;
    width: 100%;
  }
  .title {
    font-family: "Inter";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
  }

  .curve-logo {
    height: 32px;
    width: 152px;
  }
  .flags {
    display: flex;
    gap: 8px;
    img {
      height: 26px;
    }
  }
`;

export const StepNumber = styled.div`
  display: flex;
  width: 40px;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 360px;
  background: #f6f6f7;
`;

export const AppStoreLinks = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;

  img {
    cursor: pointer;
    margin-top: 24px;
    max-height: 50px;
  }
`;
