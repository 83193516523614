import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getBalanceAndPin } from "../../features/balance-and-pin/balanceAndPinSlice";
import { formattedBalance } from "../../features/balance-and-pin/utils";

import Spinner from "../../components/Spinner";

import {
  Footer,
  Wearable,
  MainContainer,
  Navbar,
  HeroContainer,
  InnerContainer,
  InfoContainer
} from "./styles";

import copyToClipboard from "../../assets/copy-icon.svg";

import digiseqLogo from "./img/digiseq-logo.svg";
import digiseqLogoSmall from "./img/digiseq-small.svg";
import crowdSyncLogo from "./img/crowdsync.svg";
import wearable from "./img/wearable.png";

import packageJson from "../../../package.json";

const Singapore24 = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");
  const [copied, setCopied] = useState(false);

  const dispatch = useDispatch();
  const { balanceAndPin, isLoading } = useSelector(
    (state) => state.balanceAndPin
  );

  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }, [copied]);

  useEffect(() => {
    if (!balanceAndPin && serialNumber) {
      dispatch(
        getBalanceAndPin({
          serial: serialNumber,
          query: "?provider=much_better"
        })
      );
    }
  }, [balanceAndPin, serialNumber, dispatch]);

  if (isLoading) return <Spinner />;

  return (
    <MainContainer>
      <Navbar>
        <img src={digiseqLogoSmall} alt="digiseq-logo" />
        <div className="balance">
          <p>Balance</p>
          <p>{`${formattedBalance(
            balanceAndPin?.balanceDetails?.availableBalance,
            balanceAndPin?.balanceDetails?.currency
          )}`}</p>
        </div>
      </Navbar>

      <Wearable className="wearable">
        <img src={wearable} alt="wearable" />
      </Wearable>

      <InnerContainer>
        <InfoContainer>
          <h1>Mastercard Haptics</h1>
          <p>
            Introducing Mastercard's latest innovation: our haptic brand
            signature. This groundbreaking feature delivers a unique vibration
            sequence when a payment transaction goes through successfully,
            mirroring our iconic sonic melody and marking the next leap in our
            multi-sensory brand journey.
          </p>
          <p>
            Five years ago, we introduced our sonic brand, recognizing that in
            today's world, a brand must transcend the visual to truly connect
            with consumers. Now, we're taking this a step further. Our haptic
            signature is designed to instill confidence, reinforce trust, and
            enhance the overall payment experience.
          </p>
          <p>
            Why does this matter? In a world of constant stimuli, brands
            communicating through just two senses risk getting lost. Our
            multi-sensory approach isn't just innovative—it's essential for
            building deeper, more emotional connections and lasting consumer
            relationships.
          </p>

          <p>
            Both our sonic and haptic signatures give cardholders added
            reassurance that their transaction has been successfully completed,
            making our brand identifiable and reinforcing security in the
            payment journey.
          </p>
        </InfoContainer>
        <InfoContainer>
          <h2>
            Who are <span className="emphasized">DIGISEQ?</span>
          </h2>
          <p>
            DIGISEQ is an award-winning IoT service platform that securely
            provisions data into everyday wearables; enabling items, from rings
            to clothing, to deliver NFC payment, customer engagement
            functionality, access control and digital identity.
          </p>
          <a href="https://www.digiseq.co.uk" target="_blank" rel="noreferrer">
            Learn more
          </a>
        </InfoContainer>

        <InfoContainer>
          <h2>
            Who are <span className="emphasized-2">CROWDSYNC?</span>
          </h2>
          <p>
            CrowdSync began with a goal of enhancing the fan experience. To
            date, our controllable LED solutions have become industry staples in
            corporate, music, and sporting events. As we look to the future, our
            engineering team develops products that not only elevate the fan
            experience, but do so in a way that is environmentally conscious.
            This is why we focus on recycling, reuse, and refurbishment.
          </p>
          <a
            href="https://www.crowdsynctechnology.com/"
            target="_blank"
            rel="noreferrer"
          >
            Learn more
          </a>
        </InfoContainer>
      </InnerContainer>

      <Footer>
        <div>
          <p>Powered by</p>
        </div>

        <img src={digiseqLogo} alt="digiseq-logo" />
        <img src={crowdSyncLogo} alt="crowdSync-logo" />

        <div className="copyToClipboard">
          <img
            onClick={() => {
              navigator.clipboard.writeText(serialNumber);
              setCopied(true);
            }}
            src={copyToClipboard}
            alt="copy to clipboard"
          />
          <p>ID: {serialNumber}</p>
        </div>
        <div className="version">
          <p>v.{packageJson.version}</p>
        </div>
        {copied && (
          <p>
            <b>Copied to clipboard</b>
          </p>
        )}
      </Footer>
    </MainContainer>
  );
};

export default Singapore24;
